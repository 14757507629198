
import { Container } from "@/components/ui/Container";
import { Footer } from "@/components/layout/Footer";
import { Navbar } from "@/components/layout/Navbar";

export default function Terms() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <main className="flex-grow pt-24 md:pt-28">
        <Container className="py-12">
          <div className="max-w-4xl mx-auto">
            <h1 className="font-display text-3xl font-bold mb-8">Terms of Service</h1>
            <p className="text-gray-500 mb-8">Last Updated: 3rd April 2025</p>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">1. Introduction</h2>
              <p className="mb-4">
                These Terms of Service ("Terms") govern your use of services provided by Fledgio Limited ("we", "our", or "us"), a company registered in England and Wales under company number 05155952, with registered office at 86-90 Paul Street, London, EC2A 4NE. Our VAT number is GB483557848.
              </p>
              <p>
                By engaging with our services, you agree to be bound by these Terms.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">2. Our Services</h2>
              <p>
                Fledgio Limited provides corporate technical training and consultancy in the areas of Data, Software, Artificial Intelligence (AI), and Cloud Computing. Services may be delivered in person, virtually, or via digital platforms.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">3. Eligibility</h2>
              <p>
                Our services are intended for corporate clients and professionals. By using our services, you represent that you are legally authorised to enter into contracts on behalf of your organisation.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">4. Booking and Payment</h2>
              <p className="mb-4">
                Training and consulting engagements are subject to written agreement and availability.
              </p>
              <p className="mb-4">
                Payment terms are specified in the agreement or invoice. Late payments may incur interest at 4% above the Bank of England base rate.
              </p>
              <p>
                Prices exclude VAT unless otherwise stated.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">5. Cancellations and Rescheduling</h2>
              <p className="mb-4">
                Cancellations must be made in writing at least 10 business days prior to the scheduled start of services.
              </p>
              <p className="mb-4">
                We reserve the right to charge up to 100% of agreed fees for late cancellations or non-attendance.
              </p>
              <p>
                Rescheduling is subject to availability and may incur additional fees.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">6. Intellectual Property</h2>
              <p>
                All training materials, documentation, and content delivered by us are the intellectual property of Fledgio Limited, unless otherwise agreed in writing. Clients may not copy, distribute, or reproduce materials without prior written consent.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">7. Confidentiality</h2>
              <p>
                We treat all client information as confidential. Likewise, clients must treat all Fledgio Limited materials and know-how as confidential and not disclose them to third parties without consent.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">8. Limitation of Liability</h2>
              <p className="mb-4">
                To the fullest extent permitted by law:
              </p>
              <p className="mb-4">
                We shall not be liable for any indirect, consequential, or incidental damages.
              </p>
              <p>
                Our liability in any matter arising from or relating to our services shall be limited to the total fees paid for those services.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">9. Data Protection</h2>
              <p>
                We process personal data in accordance with our Privacy Policy and applicable UK data protection laws (e.g., UK GDPR and the Data Protection Act 2018). Clients must ensure that any data they share with us has been lawfully obtained.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">10. Governing Law</h2>
              <p>
                These Terms shall be governed by and construed in accordance with the laws of England and Wales. Any disputes shall be subject to the exclusive jurisdiction of the English courts.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">11. Contact</h2>
              <p>
                If you have any questions about these Terms, please contact us at:
              </p>
              <address className="mt-4 not-italic">
                Fledgio Limited<br />
                86-90 Paul Street<br />
                London, EC2A 4NE<br />
                Email: <a href="mailto:info@fledgio.com" className="text-primary hover:underline">info@fledgio.com</a>
              </address>
            </section>
          </div>
        </Container>
      </main>
      
      <Footer />
    </div>
  );
}
