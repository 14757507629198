
import { supabase } from './utils';

export async function getTestimonials() {
  console.log('Fetching testimonials from Supabase...');
  
  try {
    // Explicitly using the table name 'testimonial' (singular, not plural)
    const { data, error } = await supabase
      .from('testimonial')
      .select('*')
      .order('id');
    
    if (error) {
      console.error('Error fetching testimonials:', error);
      throw error; // Throw the error to be caught by React Query
    }
    
    console.log('Testimonials fetched successfully:', data);
    return data || [];
  } catch (err) {
    console.error('Exception in getTestimonials:', err);
    throw err; // Re-throw for React Query to handle
  }
}

// Add a utility function to insert a sample testimonial if needed
export async function insertSampleTestimonial() {
  try {
    const { data, error } = await supabase
      .from('testimonial')
      .insert([
        {
          feedback: 'The Rust training course was excellent! Lawrence is a fantastic trainer who explains complex concepts clearly.',
          person_name: 'Jane Smith',
          title_company: 'Senior Developer at TechCorp',
          image_url: null
        }
      ])
      .select();
    
    if (error) {
      console.error('Error inserting sample testimonial:', error);
      throw error;
    }
    
    console.log('Sample testimonial inserted successfully:', data);
    return data;
  } catch (err) {
    console.error('Exception in insertSampleTestimonial:', err);
    throw err;
  }
}
