
import { Link } from "react-router-dom";

interface LogoProps {
  className?: string;
}

export function Logo({ className }: LogoProps) {
  return (
    <Link 
      to="/"
      className={`flex items-center ${className}`}
    >
      <img 
        src="/lovable-uploads/35192bd5-c4e3-48c0-80d1-368c895bcf8d.png" 
        alt="Fledgio" 
        className="h-28 md:h-32" 
      />
    </Link>
  );
}
