
import { useEffect, useRef } from "react";
import { Navbar } from "@/components/layout/Navbar";
import { Footer } from "@/components/layout/Footer";
import { Hero } from "@/components/home/Hero";
import { Features } from "@/components/home/Features";
import { CourseSchedule } from "@/components/home/CourseSchedule";
import { Testimonials } from "@/components/home/Testimonials";
import { CTASection } from "@/components/home/CTASection";
import { ErrorBoundary } from "@/components/ui/ErrorBoundary";
import { useLocation } from "react-router-dom";
import { SEOHead } from "@/components/seo/SEOHead";

const Index = () => {
  console.log("Rendering Index page");
  const location = useLocation();
  const testimonialsRef = useRef<HTMLDivElement>(null);
  
  // Create structured data for home page
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Fledgio",
    "url": "https://www.fledgio.com",
    "description": "Expert-led technical training in Rust Programming, Python for Data & AI, and Multi-Cloud Solutions",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://www.fledgio.com/courses?search={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };
  
  useEffect(() => {
    const handleScroll = () => {
      const reveals = document.querySelectorAll(".reveal");
      
      for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight;
        const elementTop = reveals[i].getBoundingClientRect().top;
        const elementVisible = 150;
        
        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        }
      }
    };
    
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  
  // Handle hash fragment navigation
  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      // Remove the '#' character and find the element
      const id = location.hash.substring(1);
      const element = document.getElementById(id);
      
      if (element) {
        // Wait a bit for the page to fully render before scrolling
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location.hash]);
  
  return (
    <div className="min-h-screen flex flex-col">
      <SEOHead 
        title="Fledgio - Learn to code in Rust | World Class in person Training UK"
        description="Fledgio helps businesses upskill in Rust, Data, AI, and Cloud — turning proof of concept into production-ready solutions with expert training and consulting."
        keywords="Rust Training London, Cloud Training London, Python Data Engineering Training London, Technical Training, Docker Training, Kubernetes Training, Embedded Rust, Programming Courses London"
        canonical="https://www.fledgio.com"
        structuredData={structuredData}
        isHomePage={true}
      />
      <Navbar />
      <main className="flex-grow">
        <Hero />
        <ErrorBoundary fallback={<div className="py-20 text-center">Unable to load course schedule</div>}>
          <div className="reveal mt-4">
            <CourseSchedule />
          </div>
        </ErrorBoundary>
        <div className="reveal">
          <Features />
        </div>
        <div ref={testimonialsRef} id="testimonials" className="reveal">
          <Testimonials />
        </div>
        <div className="reveal">
          <CTASection />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Index;
