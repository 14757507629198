
import { Button } from "@/components/ui/button";
import { Search } from "lucide-react";

interface CourseFiltersProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  activeCategory: string | null;
  setActiveCategory: (category: string | null) => void;
  activeLevel: string | null;
  setActiveLevel: (level: string | null) => void;
  categories: string[];
  loadingCategories: boolean;
  getCategoryEmoji: (category: string) => string;
  handleCategoryFilter: (category: string) => void;
  handleLevelFilter: (level: string) => void;
  clearFilters: () => void;
}

export const CourseFilters = ({
  searchTerm,
  setSearchTerm,
  activeCategory,
  activeLevel,
  categories,
  loadingCategories,
  getCategoryEmoji,
  handleCategoryFilter,
  handleLevelFilter,
  clearFilters,
}: CourseFiltersProps) => {
  return (
    <div className="flex flex-col md:flex-row justify-between gap-4 mb-8">
      <div className="relative w-full md:w-96">
        <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
        <input
          type="text"
          placeholder="Search courses..."
          className="w-full pl-10 pr-4 py-2 border border-border rounded-md focus:outline-none focus:ring-2 focus:ring-primary/20"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      
      <div className="flex flex-wrap gap-2">
        <div className="flex flex-wrap gap-2">
          {loadingCategories ? (
            // Skeleton loaders for categories
            <>
              <div className="h-9 w-20 bg-gray-200 animate-pulse rounded-md"></div>
              <div className="h-9 w-24 bg-gray-200 animate-pulse rounded-md"></div>
              <div className="h-9 w-22 bg-gray-200 animate-pulse rounded-md"></div>
            </>
          ) : categories.length > 0 ? (
            categories.map((category) => (
              <Button
                key={category}
                variant={activeCategory === category.toLowerCase() ? "default" : "outline"}
                size="sm"
                onClick={() => handleCategoryFilter(category.toLowerCase())}
              >
                <span className="mr-1">{getCategoryEmoji(category)}</span> {category}
              </Button>
            ))
          ) : (
            <div className="text-sm text-muted-foreground">No categories available</div>
          )}
        </div>
        
        <div className="flex gap-2">
          <Button
            variant={activeLevel === "beginner" ? "default" : "outline"}
            size="sm"
            onClick={() => handleLevelFilter("beginner")}
          >
            Beginner
          </Button>
          <Button
            variant={activeLevel === "intermediate" ? "default" : "outline"}
            size="sm"
            onClick={() => handleLevelFilter("intermediate")}
          >
            Intermediate
          </Button>
          <Button
            variant={activeLevel === "advanced" ? "default" : "outline"}
            size="sm"
            onClick={() => handleLevelFilter("advanced")}
          >
            Advanced
          </Button>
        </div>
        
        {(activeCategory || activeLevel) && (
          <Button
            variant="ghost"
            size="sm"
            onClick={clearFilters}
          >
            Clear Filters
          </Button>
        )}
      </div>
    </div>
  );
};
