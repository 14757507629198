
import { Container } from "@/components/ui/Container";
import { Link } from "react-router-dom";
import RustRadarChart from "@/components/charts/RustRadarChart";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { ContactFormModal } from "@/components/home/ContactFormModal";

export const WhyRustSection = () => {
  return (
    <section className="py-16 bg-white">
      <Container>
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="heading-1 mb-4">Why Rust has to be Taken Seriously</h2>
          <p className="text-lg text-muted-foreground">
            The momentum behind Rust adoption is transforming the industry landscape
          </p>
        </div>
        
        {/* Title spanning both chart and text */}
        <h3 className="text-xl font-semibold mb-8">
          Fledgio lowers the barrier to mastering the{" "}
          <a 
            href="https://survey.stackoverflow.co/2024/technology#2-programming-scripting-and-markup-languages" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-lite-blue hover:underline"
          >
            most admired language
          </a>{" "}
          of 2025.
        </h3>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
          {/* D3.js Radar Chart - adjusted for better vertical alignment */}
          <div className="rounded-xl overflow-hidden shadow-lg border border-gray-100 bg-white p-4">
            <RustRadarChart />
          </div>
          
          {/* Narrative Text */}
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="text-muted-foreground mb-6">
                Rust Lang is the number one language when measured by{" "}
                <a 
                  href="https://www.youtube.com/watch?v=GbWECt0M3CI&t=55s" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-lite-blue hover:underline"
                >
                  Control and Safety as expertly explained my Mozilla in this video snippet
                </a>.
              </p>
              
              {/* Sustainability paragraph with Rust Net Zero image at the beginning */}
              <div className="mb-6">
                <div className="flex items-start mb-3">
                  <img 
                    src="/lovable-uploads/848481de-a103-4ede-bff2-6a1753078acd.png" 
                    alt="Rust Net Zero" 
                    className="w-24 h-24 object-contain float-left mr-4"
                  />
                  <p className="text-muted-foreground">
                    Fledgio also believes that positively impacting <strong>Sustainability</strong> goals 
                    are achievable via choosing the right language for your production Data & AI 
                    (which can typically be very resource heavy products). Sustainability is not just 
                    a proxy for <em>performance</em> (although similar).
                  </p>
                </div>
                <p className="text-muted-foreground text-left">
                  Here's why Rust ticks all the boxes for cloud sustainability and efficiency:
                </p>
              </div>
            </div>
            
            <SustainabilityFactors />
          </div>
        </div>

        <RustCallToAction />
      </Container>
    </section>
  );
};

const SustainabilityFactors = () => {
  const factors = [
    {
      title: "Compute efficiency",
      description: "How much CPU/GPU is required to execute a task",
      impact: "High impact — less compute = lower energy usage"
    },
    {
      title: "Memory efficiency",
      description: "How much memory is consumed during operation",
      impact: "Important — RAM burns energy, especially at scale"
    },
    {
      title: "Runtime duration",
      description: "How long processes run (esp. for batch jobs, inference, or streaming)",
      impact: "High impact — less time running = less energy used"
    },
    {
      title: "Compilation optimisations",
      description: "How well the compiler produces optimised code",
      impact: "Rust is very strong here — close to C/C++ native efficiency"
    },
    {
      title: "Garbage collection overhead",
      description: "Languages with heavy GC (like Python, Java) burn cycles cleaning up memory",
      impact: "Rust avoids runtime GC, saving energy"
    },
    {
      title: "Cloud orchestration suitability",
      description: "Can the language compile to efficient, serverless, or containerised formats?",
      impact: "Rust compiles to static binaries — excellent for lean cloud deployment"
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
      {factors.map((factor, index) => (
        <div key={index} className="bg-gray-50 p-4 rounded-lg border border-gray-100 h-full flex flex-col justify-between">
          <h4 className="font-semibold text-base mb-2">{factor.title}</h4>
          <p className="text-sm text-muted-foreground mb-3">{factor.description}</p>
          <div className="flex items-center">
            <span className="text-green-500 mr-2 text-lg">✅</span>
            <span className="text-sm">{factor.impact}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

const RustCallToAction = () => {
  return (
    <div className="mt-12 p-6 bg-gray-50 rounded-xl border border-gray-100">
      <h4 className="font-semibold text-lg mb-4 text-center">Ready to Get Started with Rust?</h4>
      <p className="text-muted-foreground mb-4">
        <ContactFormModal>Get in touch</ContactFormModal> - Fledgio would love to explore the potential value outcomes you can realise by building critical systems in Rust. We offer in person exploratory sessions for how Rust can:
      </p>
      
      <ul className="list-disc pl-6 mb-4 text-muted-foreground">
        <li>Speed up the performance of your Data & AI products</li>
        <li>Decrease your safety risk</li>
        <li>Increase your TTV for achieving Sustainability Development Goals on Data & AI products</li>
      </ul>
      
      <p className="text-muted-foreground mb-4">
        Fledgio solves the problem of complexity and learning curve gradient via our world class training delivery style. It is our mission to bring-up the adoption and by bringing down the learning curve - net result: decreased carbon. <Link to="/#testimonials" className="text-lite-blue hover:underline">See our testimonials</Link> to understand why we have such confidence in our abilities.
      </p>
      
      <div className="flex justify-center mt-6">
        <Button asChild>
          <Link to="/contact">
            Contact Us Today
            <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
        </Button>
      </div>
    </div>
  );
};
