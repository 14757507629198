
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Course } from "@/types";
import { CourseCard } from "./CourseCard";

interface CourseListProps {
  loading: boolean;
  filteredCourses: Course[];
  getCategoryEmoji: (category: string) => string;
  clearFilters: () => void;
}

export const CourseList = ({ 
  loading, 
  filteredCourses, 
  getCategoryEmoji,
  clearFilters
}: CourseListProps) => {
  if (loading) {
    return <CourseSkeletons />;
  }

  if (filteredCourses.length === 0) {
    return (
      <div className="text-center py-12">
        <h2 className="text-xl font-medium mb-2">No courses found</h2>
        <p className="text-muted-foreground mb-4">
          Try adjusting your search or filters to find what you're looking for.
        </p>
        <Button variant="outline" onClick={clearFilters}>
          Reset All Filters
        </Button>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {filteredCourses.map((course) => (
        <CourseCard 
          key={course.id} 
          course={course} 
          getCategoryEmoji={getCategoryEmoji} 
        />
      ))}
    </div>
  );
};

const CourseSkeletons = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <div key={i} className="bg-white rounded-xl overflow-hidden border border-gray-100 shadow-sm p-4">
          <Skeleton className="aspect-video rounded-lg mb-4" />
          <Skeleton className="h-6 mb-2 w-3/4" />
          <Skeleton className="h-4 mb-2" />
          <Skeleton className="h-4 mb-4 w-1/2" />
          <div className="flex justify-between mt-4">
            <Skeleton className="h-6 w-1/4" />
            <Skeleton className="h-10 w-1/3" />
          </div>
        </div>
      ))}
    </div>
  );
};
