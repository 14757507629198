
import { useEffect } from 'react';

/**
 * This component adds a sitemap link to the document head to make it easier
 * for search engines to discover the sitemap.
 * 
 * The sitemap is served with the proper content-type header through 
 * server configuration or meta tags.
 */
export const SitemapLink = () => {
  useEffect(() => {
    // Check if a sitemap link already exists
    const existingLink = document.querySelector('link[rel="sitemap"]');
    
    // If no sitemap link exists, create and append it
    if (!existingLink) {
      const link = document.createElement('link');
      link.rel = 'sitemap';
      link.type = 'application/xml';  // Explicitly set the correct content type
      link.href = '/sitemap.xml';
      link.title = 'Sitemap';
      
      document.head.appendChild(link);

      // Add a meta tag to help ensure proper content type
      const meta = document.createElement('meta');
      meta.httpEquiv = 'Content-Type';
      meta.content = 'application/xml; charset=utf-8';
      meta.name = 'sitemap-content-type';
      document.head.appendChild(meta);
    }
    
    // Cleanup function to remove the link and meta when component unmounts
    return () => {
      const linkToRemove = document.querySelector('link[rel="sitemap"]');
      if (linkToRemove && linkToRemove.parentNode) {
        linkToRemove.parentNode.removeChild(linkToRemove);
      }
      
      const metaToRemove = document.querySelector('meta[name="sitemap-content-type"]');
      if (metaToRemove && metaToRemove.parentNode) {
        metaToRemove.parentNode.removeChild(metaToRemove);
      }
    };
  }, []);
  
  // This component doesn't render anything visible
  return null;
};
