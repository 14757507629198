
import { supabase } from '../utils';

export async function getAllBookings() {
  try {
    console.log('Fetching all bookings from Supabase...');
    
    const { data, error } = await supabase
      .from('booking')
      .select(`
        *,
        schedule:course_sched_id (
          *,
          course:course_id (
            code,
            tech,
            course_description
          )
        ),
        customer:customer_id (*)
      `)
      .order('created_at', { ascending: false });
      
    if (error) {
      console.error('Error fetching bookings:', error);
      return null;
    }
    
    console.log('Fetched bookings:', data);
    
    // Return the raw data for now - we can map it to a more specific type if needed
    return data;
  } catch (error) {
    console.error('Error in getAllBookings:', error);
    return null;
  }
}

export async function getBookingBySessionId(sessionId: string) {
  try {
    console.log('Fetching booking by session ID:', sessionId);
    
    const { data, error } = await supabase
      .from('booking')
      .select(`
        booking_id,
        payment_status,
        price_gbp,
        status,
        stripe_session_id,
        schedule:course_sched_id (
          schedule_id,
          location,
          start_date,
          course:course_id (
            code
          )
        ),
        customer:customer_id (
          name,
          email
        )
      `)
      .eq('stripe_session_id', sessionId)
      .maybeSingle();
      
    if (error) {
      console.error('Error fetching booking by session ID:', error);
      return null;
    }
    
    if (!data) {
      console.log('No booking found for session ID:', sessionId);
      return null;
    }
    
    console.log('Found booking:', data);
    
    // Format the data for easier consumption with null checks
    return {
      id: data.booking_id,
      status: data.status,
      payment_status: data.payment_status,
      amount: data.price_gbp,
      course_title: data.schedule?.course?.code || 'Unknown Course',
      location: data.schedule?.location || 'Unknown Location',
      start_date: data.schedule?.start_date || 'Unknown Date',
      end_date: null, // We don't have end_date directly in the database, so we'll handle this in the UI
      customer_name: data.customer?.name || 'Unknown Customer',
      customer_email: data.customer?.email || 'Unknown Email',
    };
  } catch (error) {
    console.error('Error in getBookingBySessionId:', error);
    return null;
  }
}
