
import { Routes, Route } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "@/components/ui/ErrorBoundary";
import { CookieConsent } from "@/components/ui/CookieConsent";
import { HelmetProvider } from 'react-helmet-async';
import { SitemapLink } from '@/components/seo/SitemapLink';

// Pages
import Index from '@/components/pages/Index';
import Courses from '@/pages/Courses';
import About from '@/pages/About';
import Contact from '@/pages/Contact';
import CourseDetail from '@/pages/CourseDetail';
import Booking from '@/pages/Booking';
import BookingConfirmation from '@/pages/BookingConfirmation';
import Login from '@/pages/Login';
import Dashboard from '@/pages/Dashboard';
import NotFound from '@/components/pages/NotFound';
import Privacy from '@/pages/Privacy';
import Terms from '@/pages/Terms';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <ErrorBoundary fallback={
          <div className="flex min-h-screen items-center justify-center p-4">
            <div className="text-center">
              <h1 className="text-3xl font-bold mb-4">Something went wrong</h1>
              <p className="mb-4">We're sorry, but we encountered an error loading the application.</p>
              <button 
                onClick={() => window.location.reload()}
                className="bg-primary text-white px-4 py-2 rounded"
              >
                Refresh Page
              </button>
            </div>
          </div>
        }>
          <SitemapLink />
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/courses/:slug" element={<CourseDetail />} />
            <Route path="/booking/:scheduleId" element={<Booking />} />
            <Route path="/booking-confirmation" element={<BookingConfirmation />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Toaster />
          <Sonner />
          <CookieConsent />
        </ErrorBoundary>
      </TooltipProvider>
    </QueryClientProvider>
  </HelmetProvider>
);

export default App;
