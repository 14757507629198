
import { useEffect, useState } from "react";
import { Container } from "@/components/ui/Container";
import { Star } from "lucide-react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext
} from "@/components/ui/carousel";
import { getTestimonials } from "@/lib/supabase/testimonials";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import useEmblaCarousel from "embla-carousel-react";

type Testimonial = {
  id: number;
  feedback: string | null;
  person_name: string | null;
  title_company: string | null;
  image_url: string | null;
};

export function Testimonials() {
  const [api, setApi] = useState<ReturnType<typeof useEmblaCarousel>[1]>();
  const { data: testimonials = [], isLoading, error, isSuccess } = useQuery({
    queryKey: ['testimonials'],
    queryFn: getTestimonials
  });

  // Auto-rotation effect
  useEffect(() => {
    if (!api) return;
    
    const intervalId = setInterval(() => {
      if (api.canScrollNext()) {
        api.scrollNext();
      } else {
        api.scrollTo(0); // Return to first slide when at the end
      }
    }, 9000); // Rotate every 9 seconds instead of 5 seconds
    
    return () => clearInterval(intervalId);
  }, [api]);

  useEffect(() => {
    if (error) {
      console.error('Error in testimonials query:', error);
      toast.error('Failed to load testimonials');
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      console.log('Testimonials component data:', testimonials);
      console.log('Testimonials data length:', testimonials.length);
      
      if (testimonials.length === 0) {
        // This will help diagnose if we're successfully connecting but getting no data
        console.log('No testimonials found in the database.');
      }
    }
  }, [testimonials, isSuccess]);

  // Fallback to empty array if testimonials is undefined
  const testimonialsToShow = testimonials as Testimonial[];

  // Define some sample testimonials for a better development experience
  // Only used when no testimonials are found in the database
  const sampleTestimonials: Testimonial[] = [
    {
      id: 1,
      feedback: "The Rust training provided by Lawrence was exceptional. His deep knowledge and practical approach made complex concepts accessible.",
      person_name: "Alex Johnson",
      title_company: "Senior Developer at TechCorp",
      image_url: null
    },
    {
      id: 2,
      feedback: "I've attended many technical training sessions, but Lawrence's teaching style stands out. Highly recommended for anyone wanting to learn Rust.",
      person_name: "Samantha Lee",
      title_company: "Lead Engineer at DataSystems",
      image_url: null
    }
  ];

  // Use sample testimonials if no real ones are found
  const displayTestimonials = testimonialsToShow.length > 0 
    ? testimonialsToShow 
    : sampleTestimonials;

  return (
    <section id="testimonials" className="section-padding bg-black py-20 text-white">
      <Container>
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h1 className="heading-1 mb-4 text-white">
            Impactful Training Experience
          </h1>
          <p className="text-lg text-gray-300">
            We recognise that workforce training is a big commitment. That is why we are proud to share the value that our training delivery has had on those we teach...
          </p>
        </div>
        
        {isLoading ? (
          <div className="flex justify-center py-12">
            <div className="animate-pulse flex flex-col items-center">
              <div className="h-12 w-48 bg-gray-700 rounded mb-4"></div>
              <div className="h-24 w-full max-w-2xl bg-gray-700 rounded"></div>
            </div>
          </div>
        ) : error ? (
          <div className="py-12 text-center text-red-400">
            Failed to load testimonials. Please try again later.
          </div>
        ) : (
          <div className="relative max-w-5xl mx-auto">
            {testimonialsToShow.length === 0 && (
              <div className="mb-6 p-4 bg-gray-800 border border-gray-700 rounded-md text-yellow-300">
                <p className="text-sm font-medium">
                  Note: Using sample testimonials because none were found in the database. 
                  {process.env.NODE_ENV === 'development' && (
                    <span> Add real testimonials in the Supabase dashboard.</span>
                  )}
                </p>
              </div>
            )}
            <Carousel
              opts={{
                align: "start",
                loop: true,
                dragFree: true,
                skipSnaps: true,
                inViewThreshold: 0.5,
              }}
              className="w-full"
              setApi={setApi}
            >
              <CarouselContent className="-ml-2 md:-ml-4">
                {displayTestimonials.map((testimonial) => (
                  <CarouselItem key={testimonial.id} className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/2">
                    <div className="h-full bg-gray-800 border border-gray-700 rounded-xl p-6 shadow-md">
                      <div className="flex mb-4">
                        {[...Array(5)].map((_, i) => (
                          <Star key={i} className="h-5 w-5 fill-yellow-400 text-yellow-400" />
                        ))}
                      </div>
                      
                      <blockquote className="mb-6">
                        <p className="text-white italic">"{testimonial.feedback || 'Great training experience!'}"</p>
                      </blockquote>
                      
                      <div className="flex items-center">
                        <Avatar className="h-12 w-12 rounded-full overflow-hidden mr-4 bg-gray-700">
                          {testimonial.image_url ? (
                            <AvatarImage 
                              src={testimonial.image_url} 
                              alt={testimonial.person_name || 'Testimonial'} 
                            />
                          ) : (
                            <AvatarFallback className="bg-gray-700 text-white">
                              {testimonial.person_name ? testimonial.person_name.charAt(0) : 'U'}
                            </AvatarFallback>
                          )}
                        </Avatar>
                        <div>
                          <div className="font-medium text-white">{testimonial.person_name || 'Anonymous'}</div>
                          <div className="text-sm text-gray-300">{testimonial.title_company || 'Company'}</div>
                        </div>
                      </div>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <div className="hidden md:flex justify-end gap-2 mt-4">
                <CarouselPrevious className="static transform-none bg-gray-700 text-white border-gray-600 hover:bg-gray-600" />
                <CarouselNext className="static transform-none bg-gray-700 text-white border-gray-600 hover:bg-gray-600" />
              </div>
            </Carousel>
          </div>
        )}
      </Container>
    </section>
  );
}
