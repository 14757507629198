
import { Container } from "@/components/ui/Container";
import { Link } from "react-router-dom";
import { Mail, MapPin, Phone, Linkedin, Youtube } from "lucide-react";

export function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-white border-t border-gray-200">
      <Container className="py-12 md:py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <h3 className="font-display font-semibold text-lg mb-4">About Fledgio</h3>
            <p className="text-sm text-gray-600 mt-4">
              Specializing in Rust, Python for Data/AI, Docker, Kubernetes, and multi-cloud solutions.
            </p>
            <div className="flex space-x-4 text-gray-500">
              <a href="https://linkedin.com" className="hover:text-primary transition-colors" aria-label="LinkedIn">
                <span className="sr-only">LinkedIn</span>
                <Linkedin className="w-5 h-5" />
              </a>
              <a href="https://medium.com" className="hover:text-primary transition-colors" aria-label="Medium">
                <span className="sr-only">Medium</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-5 h-5">
                  <path d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"></path>
                  <path d="M8 9l4 4 4-4"></path>
                  <path d="M8 12h8"></path>
                  <path d="M8 15h8"></path>
                </svg>
              </a>
              <a href="https://bsky.app" className="hover:text-primary transition-colors" aria-label="Bluesky">
                <span className="sr-only">Bluesky</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-5 h-5">
                  <path d="M8 16s1.5-2 4-2 4 2 4 2"></path>
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="m9 10 1.5-1.5a2.121 2.121 0 0 1 3 0L15 10"></path>
                </svg>
              </a>
              <a href="https://youtube.com" className="hover:text-primary transition-colors" aria-label="YouTube">
                <span className="sr-only">YouTube</span>
                <Youtube className="w-5 h-5" />
              </a>
            </div>
          </div>
          
          {/* Navigation Links */}
          <div>
            <h3 className="font-display font-semibold text-lg mb-4">Quick Links</h3>
            <ul className="space-y-2 text-sm">
              <li><Link to="/" className="text-gray-600 hover:text-primary transition-colors">Home</Link></li>
              <li><Link to="/courses" className="text-gray-600 hover:text-primary transition-colors">Courses</Link></li>
              <li><Link to="/about" className="text-gray-600 hover:text-primary transition-colors">About Us</Link></li>
              <li><Link to="/blog" className="text-gray-600 hover:text-primary transition-colors">Blog</Link></li>
              <li><Link to="/contact" className="text-gray-600 hover:text-primary transition-colors">Contact</Link></li>
            </ul>
          </div>
          
          {/* Courses */}
          <div>
            <h3 className="font-display font-semibold text-lg mb-4">Our Courses</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link to="/courses?category=rust" className="text-gray-600 hover:text-primary transition-colors">
                  <span className="mr-2">🦀</span>Rust Programming
                </Link>
              </li>
              <li>
                <Link to="/courses?category=python" className="text-gray-600 hover:text-primary transition-colors">
                  <span className="mr-2">🐍</span>Python & Data Science
                </Link>
              </li>
              <li>
                <Link to="/courses?category=cloud" className="text-gray-600 hover:text-primary transition-colors">
                  <span className="mr-2">🐳</span>Docker & Kubernetes
                </Link>
              </li>
            </ul>
          </div>
          
          {/* Contact Information */}
          <div>
            <h3 className="font-display font-semibold text-lg mb-4">Contact Us</h3>
            <ul className="space-y-3 text-sm">
              <li className="flex items-start">
                <MapPin className="w-5 h-5 text-gray-500 mr-2 mt-0.5" />
                <span className="text-gray-600">Fledgio Limited, 86-90 Paul Street, London, EC2A 4NE</span>
              </li>
              <li className="flex items-center">
                <Phone className="w-5 h-5 text-gray-500 mr-2" />
                <span className="text-gray-600">+44 (0) 20 4525 9691</span>
              </li>
              <li className="flex items-center">
                <Mail className="w-5 h-5 text-gray-500 mr-2" />
                <a href="mailto:info@fledgio.com" className="text-gray-600 hover:text-primary transition-colors">
                  info@fledgio.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-gray-200 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center">
          <div className="text-sm text-gray-600">
            <p>&copy; {currentYear} Fledgio. All rights reserved.</p>
            <p className="mt-1">Company Number: 05155952 | VAT Reg: GB483557848</p>
          </div>
          <div className="mt-4 md:mt-0 flex space-x-4 text-sm">
            <Link to="/privacy" className="text-gray-600 hover:text-primary transition-colors">
              Privacy Policy
            </Link>
            <Link to="/terms" className="text-gray-600 hover:text-primary transition-colors">
              Terms of Service
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  );
}
