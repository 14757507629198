
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

interface SEOHeadProps {
  title: string;
  description: string;
  keywords?: string;
  ogImage?: string;
  ogImageAlt?: string;
  ogUrl?: string;
  canonical?: string;
  structuredData?: Record<string, any>;
  noIndex?: boolean;
  isHomePage?: boolean;
}

export const SEOHead = ({
  title,
  description,
  keywords,
  ogImage = "https://www.fledgio.com/lovable-uploads/f378e853-61f6-4d4e-8ceb-67eba5fcc775.png",
  ogImageAlt,
  ogUrl,
  canonical,
  structuredData,
  noIndex = false,
  isHomePage = false,
}: SEOHeadProps) => {
  const location = useLocation();
  
  // Generate canonical URL if not explicitly provided
  // Always use www.fledgio.com for canonical URLs
  const canonicalUrl = canonical || `https://www.fledgio.com${location.pathname}`;
  
  // Generate JSON-LD Schema
  const generateSchemaMarkup = () => {
    if (!structuredData) return null;
    
    return JSON.stringify(structuredData);
  };

  // Custom OG tags for home page
  const homePageOgTitle = "Fledgio | Rust, Data, AI, and Cloud Training & Consulting";
  const homePageOgDescription = "Helping your workforce fledge into advanced technology — from proof of concept to production. Specialists in Rust for high-performance, sustainable solutions.";
  const homePageOgImage = "https://www.fledgio.com/assets/og-image.jpg";
  const homePageOgImageAlt = "Fledgio logo with Rust, AI, and Cloud visual elements";

  return (
    <Helmet>
      {/* Google Analytics */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-6S5HS8G680"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-6S5HS8G680');
        `}
      </script>
      
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      
      {/* Canonical URL - always include this */}
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={isHomePage ? homePageOgTitle : title} />
      <meta property="og:description" content={isHomePage ? homePageOgDescription : description} />
      <meta property="og:image" content={isHomePage ? homePageOgImage : ogImage} />
      {(isHomePage || ogImageAlt) && (
        <meta property="og:image:alt" content={isHomePage ? homePageOgImageAlt : ogImageAlt} />
      )}
      <meta property="og:url" content={isHomePage ? "https://www.fledgio.com/" : (ogUrl || canonicalUrl)} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={isHomePage ? homePageOgTitle : title} />
      <meta name="twitter:description" content={isHomePage ? homePageOgDescription : description} />
      <meta name="twitter:image" content={isHomePage ? homePageOgImage : ogImage} />
      {(isHomePage || ogImageAlt) && (
        <meta name="twitter:image:alt" content={isHomePage ? homePageOgImageAlt : ogImageAlt} />
      )}
      
      {/* No Index if specified */}
      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      
      {/* JSON-LD Schema Markup */}
      {structuredData && (
        <script type="application/ld+json">
          {generateSchemaMarkup()}
        </script>
      )}
    </Helmet>
  );
};
