
import { useState, useEffect } from "react";
import { Calendar, ArrowRight, MapPin, Users } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Container } from "@/components/ui/Container";
import { Link } from "react-router-dom";
import { getUpcomingCourses } from "@/lib/supabase";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { toast } from "@/hooks/use-toast";
import { CourseSchedule as CourseScheduleType } from "@/types";

interface CourseScheduleItem {
  id: string;
  courseId: string;
  startDate: string;
  endDate: string;
  location: string;
  availableSeats: number;
  bookedSeats: number;
  price: number;
  status: "upcoming" | "ongoing" | "completed" | "cancelled";
  createdAt: string;
  updatedAt: string;
  course?: {
    id: string;
    title: string;
    category: string;
    slug: string;
  };
}

export function CourseSchedule() {
  const [schedules, setSchedules] = useState<CourseScheduleItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchCourseSchedules() {
      try {
        console.log("Fetching course schedules...");
        
        if (process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_USE_MOCK_DATA === 'true') {
          console.log("Using mock data for CourseSchedule in development");
          const mockData: CourseScheduleItem[] = [
            {
              id: "1",
              courseId: "rust-101",
              startDate: "2023-12-10",
              endDate: "2023-12-14",
              location: "London",
              availableSeats: 5,
              bookedSeats: 0,
              price: 1299,
              status: "upcoming",
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              course: {
                id: "1",
                title: "Rust Programming Fundamentals",
                category: "rust",
                slug: "rust-fundamentals",
              },
            },
            {
              id: "2",
              courseId: "python-data",
              startDate: "2023-12-15",
              endDate: "2023-12-17",
              location: "London",
              availableSeats: 3,
              bookedSeats: 0,
              price: 999,
              status: "upcoming",
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              course: {
                id: "2",
                title: "Python for Data Engineering",
                category: "python",
                slug: "python-data-engineering",
              },
            },
            {
              id: "3",
              courseId: "kubernetes",
              startDate: "2024-01-10",
              endDate: "2024-01-14",
              location: "Online",
              availableSeats: 10,
              bookedSeats: 0,
              price: 1499,
              status: "upcoming",
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              course: {
                id: "3",
                title: "Kubernetes for Multi-Cloud",
                category: "cloud",
                slug: "kubernetes-multi-cloud",
              },
            },
          ];
          
          setSchedules(mockData);
          setLoading(false);
          return;
        }
        
        const coursesData = await getUpcomingCourses();
        
        console.log("Received data from getUpcomingCourses:", coursesData);
        
        if (!coursesData) {
          console.error("No course schedules returned from Supabase");
          setError("Failed to load upcoming courses. Please try again later.");
          toast({
            title: "Error",
            description: "Failed to load upcoming courses. Please try again later.",
            variant: "destructive",
          });
          setLoading(false);
          return;
        }
        
        if (Array.isArray(coursesData) && coursesData.length > 0) {
          console.log("Successfully fetched course schedules:", coursesData);
          setSchedules(coursesData as CourseScheduleItem[]);
        } else {
          console.log("No upcoming courses found or empty array returned");
          // This is not an error state, just no courses available
          setSchedules([]);
        }
      } catch (err) {
        console.error("Error in fetchCourseSchedules:", err);
        toast({
          title: "Error",
          description: "Failed to load upcoming courses. Please try again later.",
          variant: "destructive",
        });
        setError("An unexpected error occurred. Please try again later.");
      } finally {
        setLoading(false);
      }
    }

    fetchCourseSchedules();
  }, []);

  const formatDateRange = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      return "Date TBD";
    }
    
    const startMonth = start.toLocaleString('default', { month: 'short' });
    const endMonth = end.toLocaleString('default', { month: 'short' });
    
    if (startMonth === endMonth && start.getFullYear() === end.getFullYear()) {
      return `${start.getDate()} - ${end.getDate()} ${startMonth} ${start.getFullYear()}`;
    } else {
      return `${start.getDate()} ${startMonth} - ${end.getDate()} ${endMonth} ${end.getFullYear()}`;
    }
  };

  const getCategoryEmoji = (category: string) => {
    const categoryLower = category ? category.toLowerCase() : '';
    switch (categoryLower) {
      case 'rust':
        return '🦀';
      case 'python':
        return '🐍';
      case 'cloud':
      case 'kubernetes':
      case 'docker':
        return '🐳';
      default:
        return '📚';
    }
  };

  return (
    <section className="section-padding bg-white" id="upcoming-courses">
      <Container>
        <div className="max-w-3xl mx-auto text-center mb-10">
          <h2 className="heading-1 mb-4">Upcoming Training Courses</h2>
          <p className="text-lg text-muted-foreground">
            Book your place on our expert-led training courses in London and online
          </p>
        </div>

        {loading ? (
          <div className="space-y-4">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="bg-white p-6 rounded-lg border">
                <Skeleton className="h-8 w-3/4 mb-4" />
                <div className="grid md:grid-cols-3 gap-4">
                  <Skeleton className="h-6 w-full" />
                  <Skeleton className="h-6 w-full" />
                  <Skeleton className="h-6 w-full" />
                </div>
              </div>
            ))}
          </div>
        ) : error ? (
          <div className="text-center py-8">
            <p className="text-destructive mb-4">{error}</p>
            <Button onClick={() => window.location.reload()}>Try Again</Button>
          </div>
        ) : schedules.length === 0 ? (
          <div className="text-center py-8 bg-muted rounded-lg">
            <Calendar className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
            <h3 className="text-xl font-medium mb-2">No Upcoming Courses</h3>
            <p className="text-muted-foreground mb-4">
              Check back soon for new course schedules or contact us for private training options.
            </p>
            <Button asChild>
              <Link to="/contact">Contact Us</Link>
            </Button>
          </div>
        ) : (
          <div className="bg-white rounded-lg border overflow-hidden">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Course</TableHead>
                  <TableHead className="hidden md:table-cell">Dates</TableHead>
                  <TableHead className="hidden md:table-cell">Location</TableHead>
                  <TableHead className="hidden md:table-cell">Availability</TableHead>
                  <TableHead className="hidden md:table-cell">Price</TableHead>
                  <TableHead className="text-right">Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {schedules.map((schedule) => (
                  <TableRow key={schedule.id}>
                    <TableCell>
                      <div>
                        <div className="font-medium flex items-center">
                          <span className="mr-2">{getCategoryEmoji(schedule.course?.category || '')}</span>
                          {schedule.course?.title || 'Course'}
                        </div>
                        <div className="text-sm text-muted-foreground md:hidden flex items-center mt-1">
                          <Calendar className="h-3 w-3 mr-1" />
                          {formatDateRange(schedule.startDate, schedule.endDate)}
                        </div>
                        <div className="text-sm text-muted-foreground md:hidden flex items-center mt-1">
                          <MapPin className="h-3 w-3 mr-1" />
                          {schedule.location}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="hidden md:table-cell">
                      <div className="flex items-center">
                        <Calendar className="h-4 w-4 mr-2 text-muted-foreground" />
                        {formatDateRange(schedule.startDate, schedule.endDate)}
                      </div>
                    </TableCell>
                    <TableCell className="hidden md:table-cell">
                      <div className="flex items-center">
                        <MapPin className="h-4 w-4 mr-2 text-muted-foreground" />
                        {schedule.location}
                      </div>
                    </TableCell>
                    <TableCell className="hidden md:table-cell">
                      <div className="flex items-center">
                        <Users className="h-4 w-4 mr-2 text-muted-foreground" />
                        {schedule.availableSeats > 0 ? (
                          <span className="text-green-600">{schedule.availableSeats} places left</span>
                        ) : (
                          <span className="text-destructive">Fully booked</span>
                        )}
                      </div>
                    </TableCell>
                    <TableCell className="hidden md:table-cell">
                      £{schedule.price.toLocaleString()}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button 
                        asChild 
                        size="sm" 
                        variant={schedule.availableSeats > 0 ? "default" : "outline"}
                        disabled={schedule.availableSeats === 0}
                      >
                        <Link to={`/booking/${schedule.id}`}>
                          {schedule.availableSeats > 0 ? "Book Now" : "Waitlist"}
                        </Link>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="p-4 border-t text-center">
              <Link 
                to="/courses" 
                className="inline-flex items-center text-primary hover:underline font-medium"
              >
                View All Courses
                <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
            </div>
          </div>
        )}
      </Container>
    </section>
  );
}
