
import { Container } from "@/components/ui/Container";
import { Footer } from "@/components/layout/Footer";
import { Navbar } from "@/components/layout/Navbar";

export default function Privacy() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <main className="flex-grow pt-24 md:pt-28">
        <Container className="py-12">
          <div className="max-w-4xl mx-auto">
            <h1 className="font-display text-3xl font-bold mb-8">Privacy Policy</h1>
            <p className="text-gray-500 mb-8">Last Updated: 3rd April 2025</p>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">1. Introduction</h2>
              <p className="mb-4">
                Fledgio Limited ("we", "us", "our") is committed to protecting and respecting your privacy. This Privacy Policy outlines how we collect, use, and protect your personal data in accordance with the UK General Data Protection Regulation (UK GDPR) and the Data Protection Act 2018.
              </p>
              <p className="mb-4">
                Company Name: Fledgio Limited<br />
                Company Number: 05155952<br />
                VAT Number: GB483557848<br />
                Registered Office: 86-90 Paul Street, London, EC2A 4NE
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">2. Information We Collect</h2>
              <p className="mb-4">We collect the following categories of data:</p>
              
              <h3 className="font-semibold mb-2">a) Information you provide directly:</h3>
              <ul className="list-disc pl-6 mb-4">
                <li>Full name, job title, email address, phone number</li>
                <li>Company name and billing information</li>
                <li>Communications sent to us (e.g., contact forms, emails)</li>
              </ul>
              
              <h3 className="font-semibold mb-2">b) Information we collect automatically:</h3>
              <ul className="list-disc pl-6 mb-4">
                <li>IP address, browser type, device, and operating system</li>
                <li>Pages visited, time on site, referring website</li>
                <li>Interaction with our emails and online content</li>
              </ul>
              
              <h3 className="font-semibold mb-2">c) Information collected via third-party tools:</h3>
              <ul className="list-disc pl-6 mb-4">
                <li>Google Analytics or similar for SEO and web usage tracking</li>
                <li>Cookies for improving website experience and functionality</li>
              </ul>
              
              <h3 className="font-semibold mb-2">d) Information logged in our CRM:</h3>
              <ul className="list-disc pl-6 mb-4">
                <li>Details of business enquiries and interactions</li>
                <li>Notes and summaries of logged calls (call recordings are not stored unless stated otherwise)</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">3. Use of Cookies</h2>
              <p className="mb-4">Our website uses cookies and similar technologies to:</p>
              <ul className="list-disc pl-6 mb-4">
                <li>Provide core functionality</li>
                <li>Track performance and site usage</li>
                <li>Improve our website experience</li>
                <li>Measure marketing and SEO performance</li>
              </ul>
              <p>You will be prompted to accept or decline non-essential cookies when you first visit our site. You can manage your preferences at any time through your browser settings.</p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">4. How We Use Your Data</h2>
              <p className="mb-4">We process personal data for the following purposes:</p>
              <ul className="list-disc pl-6 mb-4">
                <li>To provide and manage our corporate training and consulting services</li>
                <li>To respond to enquiries and manage client relationships</li>
                <li>To analyse site usage and optimise our content and marketing</li>
                <li>To improve our services and customer experience</li>
                <li>To comply with legal and tax obligations</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">5. Lawful Basis for Processing</h2>
              <p className="mb-4">We rely on the following lawful bases:</p>
              <ul className="list-disc pl-6 mb-4">
                <li>Contractual necessity – when delivering our services</li>
                <li>Legitimate interests – for CRM use, analytics, marketing, and business development</li>
                <li>Consent – for cookie use and email marketing (where applicable)</li>
                <li>Legal obligation – for tax and compliance</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">6. Data Sharing and Third Parties</h2>
              <p className="mb-4">We may share your data with trusted third parties:</p>
              <ul className="list-disc pl-6 mb-4">
                <li>CRM and email marketing platforms</li>
                <li>Web analytics providers (e.g., Google Analytics)</li>
                <li>Payment processors and accounting tools</li>
                <li>Legal or regulatory bodies where required</li>
              </ul>
              <p>We ensure all partners meet UK data protection requirements.</p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">7. Data Retention</h2>
              <p className="mb-4">We retain personal data only as long as necessary:</p>
              <ul className="list-disc pl-6 mb-4">
                <li>CRM and contact data: typically retained for up to 7 years</li>
                <li>Financial and invoicing records: retained for 6+ years (legal requirement)</li>
                <li>Web analytics data: retained per third-party platform default (e.g., 26 months for Google Analytics)</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">8. International Data Transfers</h2>
              <p>
                We may transfer data to third-party service providers outside the UK or EEA. Where we do, we ensure appropriate safeguards are in place, such as Standard Contractual Clauses or adequacy decisions.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">9. Your Rights</h2>
              <p className="mb-4">You have the right to:</p>
              <ul className="list-disc pl-6 mb-4">
                <li>Access your data</li>
                <li>Request correction or deletion</li>
                <li>Object to processing or request restriction</li>
                <li>Withdraw consent (e.g., for cookies or marketing)</li>
                <li>Data portability</li>
                <li>Lodge a complaint with the ICO (Information Commissioner's Office)</li>
              </ul>
              <p>To exercise these rights, contact us at <a href="mailto:info@fledgio.com" className="text-primary hover:underline">info@fledgio.com</a>.</p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">10. Data Security</h2>
              <p>
                We use appropriate technical and organisational measures to protect your personal data, including secure access controls, encryption, and regular review of third-party tools.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">11. Updates to This Policy</h2>
              <p>
                We may update this Privacy Policy to reflect legal, operational, or technical changes. The latest version will always be available on our website, with the "Last Updated" date shown at the top.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="font-display text-xl font-semibold mb-4">12. Contact Us</h2>
              <p className="mb-4">
                For privacy-related questions or data access requests, contact:
              </p>
              <address className="mt-4 not-italic">
                Fledgio Limited<br />
                86-90 Paul Street<br />
                London, EC2A 4NE<br />
                Email: <a href="mailto:info@fledgio.com" className="text-primary hover:underline">info@fledgio.com</a>
              </address>
            </section>
          </div>
        </Container>
      </main>
      
      <Footer />
    </div>
  );
}

