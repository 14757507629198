
import { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { Navbar } from "@/components/layout/Navbar";
import { Footer } from "@/components/layout/Footer";
import { Container } from "@/components/ui/Container";
import { Button } from "@/components/ui/button";
import { CheckCircle2, AlertTriangle, Clock } from "lucide-react";
import { toast } from "@/hooks/use-toast";
import { getBookingBySessionId } from "@/lib/supabase/bookings";

const BookingConfirmation = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<"loading" | "success" | "error" | "pending">("loading");
  const [bookingDetails, setBookingDetails] = useState<any>(null);
  
  const sessionId = searchParams.get("session_id");
  const statusParam = searchParams.get("status");
  
  // Add meta tag for no indexing
  useEffect(() => {
    // Create meta tag
    const metaRobots = document.createElement('meta');
    metaRobots.name = "robots";
    metaRobots.content = "noindex";
    document.head.appendChild(metaRobots);
    
    // Cleanup function
    return () => {
      const existingTag = document.querySelector('meta[name="robots"]');
      if (existingTag && existingTag.parentNode) {
        existingTag.parentNode.removeChild(existingTag);
      }
    };
  }, []);
  
  useEffect(() => {
    async function checkBookingStatus() {
      if (statusParam === "pending") {
        setStatus("pending");
        return;
      }
      
      if (!sessionId) {
        setStatus("error");
        return;
      }
      
      try {
        const booking = await getBookingBySessionId(sessionId);
        
        if (!booking) {
          setStatus("error");
          return;
        }
        
        setBookingDetails(booking);
        setStatus("success");
      } catch (error) {
        console.error("Error verifying booking:", error);
        setStatus("error");
        toast({
          title: "Error",
          description: "We couldn't verify your booking. Please contact support.",
          variant: "destructive",
        });
      }
    }
    
    checkBookingStatus();
  }, [sessionId, statusParam]);
  
  const basePrice = bookingDetails?.amount || 0;
  const vatRate = 0.20;
  const vatAmount = basePrice * vatRate;
  const totalPrice = basePrice + vatAmount;
  
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow pt-36 md:pt-48">
        <Container>
          <div className="max-w-3xl mx-auto">
            {status === "loading" && (
              <div className="text-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary mx-auto"></div>
                <p className="mt-4 text-muted-foreground">Verifying your booking...</p>
              </div>
            )}
            
            {status === "pending" && (
              <div className="text-center py-12">
                <div className="bg-amber-50 rounded-full h-24 w-24 flex items-center justify-center mx-auto mb-6">
                  <Clock className="h-12 w-12 text-amber-500" />
                </div>
                <h1 className="heading-1 mb-4">Completing Your Booking</h1>
                <p className="text-lg text-muted-foreground mb-8">
                  We've opened Stripe's checkout in a new tab. Please complete your payment there to confirm your booking.
                </p>
                
                <p className="text-muted-foreground mb-6">
                  Once payment is completed, you'll receive a confirmation email with all the details.
                </p>
                
                <div className="space-x-4">
                  <Button asChild>
                    <Link to="/courses">Browse More Courses</Link>
                  </Button>
                </div>
              </div>
            )}
            
            {status === "success" && (
              <div className="text-center py-12">
                <div className="bg-green-50 rounded-full h-24 w-24 flex items-center justify-center mx-auto mb-6">
                  <CheckCircle2 className="h-12 w-12 text-green-500" />
                </div>
                <h1 className="heading-1 mb-4">Booking Confirmed!</h1>
                <p className="text-lg text-muted-foreground mb-8">
                  Thank you for booking your place on "{bookingDetails.course_title}"
                </p>
                
                <div className="bg-muted p-6 rounded-lg mb-8 text-left">
                  <h2 className="text-xl font-semibold mb-4">Booking Details</h2>
                  <div className="space-y-4">
                    <div>
                      <div className="text-sm text-muted-foreground">Course</div>
                      <div className="font-medium">{bookingDetails.course_title}</div>
                    </div>
                    <div>
                      <div className="text-sm text-muted-foreground">Date</div>
                      <div className="font-medium">{new Date(bookingDetails.start_date).toLocaleDateString()} - {new Date(bookingDetails.end_date).toLocaleDateString()}</div>
                    </div>
                    <div>
                      <div className="text-sm text-muted-foreground">Location</div>
                      <div className="font-medium">{bookingDetails.location}</div>
                    </div>
                    <div>
                      <div className="text-sm text-muted-foreground">Price</div>
                      <div className="font-medium">
                        <div>£{basePrice.toLocaleString()} (ex. VAT)</div>
                        <div className="text-sm">VAT (20%): £{vatAmount.toLocaleString()}</div>
                        <div>Total: £{totalPrice.toLocaleString()}</div>
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-muted-foreground">Reference</div>
                      <div className="font-medium">{bookingDetails.id}</div>
                    </div>
                  </div>
                </div>
                
                <p className="text-muted-foreground mb-6">
                  We've sent a confirmation email to your registered email address with all the details.
                </p>
                
                <Button asChild>
                  <Link to="/courses">Browse More Courses</Link>
                </Button>
              </div>
            )}
            
            {status === "error" && (
              <div className="text-center py-12">
                <div className="bg-red-50 rounded-full h-24 w-24 flex items-center justify-center mx-auto mb-6">
                  <AlertTriangle className="h-12 w-12 text-red-500" />
                </div>
                <h1 className="heading-1 mb-4">Booking Verification Failed</h1>
                <p className="text-lg text-muted-foreground mb-8">
                  We couldn't verify your booking. This may be due to:
                </p>
                
                <ul className="list-disc text-left max-w-md mx-auto mb-8 space-y-2">
                  <li>An invalid or expired session</li>
                  <li>A payment that wasn't completed</li>
                  <li>A technical issue with our system</li>
                </ul>
                
                <p className="text-muted-foreground mb-6">
                  If you believe this is an error, please contact our support team.
                </p>
                
                <div className="space-x-4">
                  <Button asChild variant="outline">
                    <Link to="/courses">Back to Courses</Link>
                  </Button>
                  <Button asChild>
                    <Link to="/contact">Contact Support</Link>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default BookingConfirmation;
