
import { Container } from "@/components/ui/Container";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ArrowRight, Calendar, Users, BookOpen } from "lucide-react";

export function CTASection() {
  return (
    <section className="py-16 relative overflow-hidden">
      {/* Background gradient */}
      <div className="absolute inset-0 bg-gradient-to-r from-lite-blue/10 via-lite-green/10 to-lite-red/10" />
      
      <Container className="relative z-10">
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            {/* Content */}
            <div className="p-8 md:p-12">
              <h2 className="heading-2 mb-4">Ready to Take Your Skills to the Next Level?</h2>
              <p className="text-muted-foreground mb-6">
                Join thousands of professionals who have accelerated their careers through the expertise and world class technical training delivery of thedatachef.
              </p>
              
              <div className="space-y-4 mb-8">
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 rounded-full bg-lite-blue/10 flex items-center justify-center mt-1 mr-3">
                    <Calendar className="h-3.5 w-3.5 text-lite-blue" />
                  </div>
                  <div>
                    <h3 className="font-medium">Flexible Scheduling</h3>
                    <p className="text-sm text-muted-foreground">Both public and corporate private training options available</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 rounded-full bg-lite-green/10 flex items-center justify-center mt-1 mr-3">
                    <Users className="h-3.5 w-3.5 text-lite-green" />
                  </div>
                  <div>
                    <h3 className="font-medium">Expert-Led Training</h3>
                    <p className="text-sm text-muted-foreground">Learn from TheDataChef with 25+ years of industry experience</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 rounded-full bg-lite-red/10 flex items-center justify-center mt-1 mr-3">
                    <BookOpen className="h-3.5 w-3.5 text-lite-red" />
                  </div>
                  <div>
                    <h3 className="font-medium">Practical Curriculum</h3>
                    <p className="text-sm text-muted-foreground">Build real-world solutions you can immediately apply</p>
                  </div>
                </div>
              </div>
              
              <div className="space-y-4 sm:space-y-0 sm:space-x-4 sm:flex">
                <Button asChild size="lg">
                  <Link to="/courses">
                    Browse Courses
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </Button>
                <Button asChild variant="outline" size="lg">
                  <Link to="/contact">Contact for Corporate Training</Link>
                </Button>
              </div>
            </div>
            
            {/* Image */}
            <div className="relative hidden lg:block">
              <div className="absolute inset-0 bg-gradient-to-r from-lite-blue/20 to-primary/40" />
              <img 
                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" 
                alt="Collaborative learning" 
                className="h-full w-full object-cover"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
