
import { Navbar } from "@/components/layout/Navbar";
import { Footer } from "@/components/layout/Footer";
import { AboutHero } from "@/components/about/AboutHero";
import { WhyRustSection } from "@/components/about/WhyRustSection";
import { PhilosophySection } from "@/components/about/PhilosophySection";
import { DataChefSection } from "@/components/about/DataChefSection";
import { StatsSection } from "@/components/about/StatsSection";
import { CTASection } from "@/components/about/CTASection";
import { SEOHead } from "@/components/seo/SEOHead";
import "@/components/charts/RustRadarChart.css";

const About = () => {
  // Create structured data for organization
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Fledgio",
    "url": "https://www.fledgio.com",
    "logo": "https://www.fledgio.com/lovable-uploads/7ae20db5-7b4f-43c0-a0a8-34112dc8098e.png",
    "description": "Expert-led technical training in Rust Programming, Python for Data & AI, and Multi-Cloud Solutions.",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "London",
      "addressCountry": "UK"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+44-000-000-0000",
      "contactType": "customer service"
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <SEOHead 
        title="About Fledgio | Expert Rust & Cloud Training"
        description="Learn about Fledgio and our mission to provide expert-led technical training in Rust Programming, Python, and Multi-Cloud Solutions in London."
        keywords="Rust Training London, About Fledgio, The Data Chef, Expert Technical Training"
        canonical="https://www.fledgio.com/about"
        structuredData={structuredData}
      />
      <Navbar />
      <main className="flex-grow pt-36 md:pt-48">
        {/* Hero Section */}
        <AboutHero />
        
        {/* Why Rust has to be Taken Seriously Section */}
        <WhyRustSection />
        
        {/* Our Philosophy */}
        <PhilosophySection />
        
        {/* Meet The Data Chef */}
        <DataChefSection />
        
        {/* Stats Section */}
        <StatsSection />
        
        {/* CTA Section */}
        <CTASection />
      </main>
      <Footer />
    </div>
  );
};

export default About;
