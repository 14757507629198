
import { Container } from "@/components/ui/Container";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ArrowRight, Users, BookOpen, Briefcase } from "lucide-react";

export const CTASection = () => {
  return (
    <section className="py-16 bg-gradient-to-r from-lite-blue/10 via-lite-green/10 to-lite-red/10">
      <Container>
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            {/* Content */}
            <div className="p-8 md:p-12">
              <h2 className="heading-2 mb-4">Ready to Elevate Your Team's Technical Skills?</h2>
              <p className="text-muted-foreground mb-6">
                Whether you're looking for public courses or customized corporate training, 
                we're here to help your team master modern technologies.
              </p>
              
              <div className="space-y-4 mb-8">
                <FeatureItem
                  icon={<Briefcase className="h-3.5 w-3.5 text-lite-blue" />}
                  title="Corporate Training"
                  description="Customized programs for your team's specific needs"
                  bgColor="bg-lite-blue/10"
                />
                
                <FeatureItem
                  icon={<Users className="h-3.5 w-3.5 text-lite-green" />}
                  title="Public Courses"
                  description="Regular scheduled training sessions in London"
                  bgColor="bg-lite-green/10"
                />
                
                <FeatureItem
                  icon={<BookOpen className="h-3.5 w-3.5 text-lite-red" />}
                  title="Customized Curriculum"
                  description="Tailored content focused on your industry challenges"
                  bgColor="bg-lite-red/10"
                />
              </div>
              
              <div className="space-y-4 sm:space-y-0 sm:space-x-4 sm:flex">
                <Button asChild size="lg">
                  <Link to="/contact">
                    Contact Our Team
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </Button>
                <Button asChild variant="outline" size="lg">
                  <Link to="/courses">Browse Courses</Link>
                </Button>
              </div>
            </div>
            
            {/* Image */}
            <div className="relative hidden lg:block">
              <div className="absolute inset-0 bg-gradient-to-r from-lite-blue/20 to-primary/40" />
              <img 
                src="https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80" 
                alt="Team collaboration" 
                className="h-full w-full object-cover"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

interface FeatureItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  bgColor: string;
}

const FeatureItem = ({ icon, title, description, bgColor }: FeatureItemProps) => (
  <div className="flex items-start">
    <div className={`flex-shrink-0 h-6 w-6 rounded-full ${bgColor} flex items-center justify-center mt-1 mr-3`}>
      {icon}
    </div>
    <div>
      <h3 className="font-medium">{title}</h3>
      <p className="text-sm text-muted-foreground">{description}</p>
    </div>
  </div>
);
