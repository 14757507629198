
import { Container } from "@/components/ui/Container";
import { Code, Database, Cloud, BarChart4, Layers, Shield } from "lucide-react";

const features = [
  {
    name: "Practical Real-World Training",
    description: "Build actual products and solutions during labs, gaining hands-on experience that translates directly to your work environment.",
    icon: Code,
  },
  {
    name: "Industry-Leading Experts",
    description: "Learn from TheDataChef and other specialists with 25+ years of experience in tech, sharing insider knowledge and best practices.",
    icon: BarChart4,
  },
  {
    name: "Cross-Disciplinary Curriculum",
    description: "Comprehensive courses spanning technology from scalable cloud infrastructure and containerisation, Python for data processing, throwing up POC's in Python, and the elegance and safety of Rust in production.",
    icon: Layers,
  },
  {
    name: "Security First Approach",
    description: "Learn how to build secure systems from the ground up, not as an afterthought, ensuring robust and protected solutions.",
    icon: Shield,
  },
  {
    name: "Multi-Cloud Expertise",
    description: "Master infrastructure and deployment across AWS, Azure, and Google Cloud, with a focus on platform-agnostic skills.",
    icon: Cloud,
  },
  {
    name: "'As-Code' Philosophy",
    description: "Embrace infrastructure as code, configuration as code, and security as code for repeatable, testable, and maintainable systems.",
    icon: Database,
  },
];

export function Features() {
  return (
    <section className="bg-white py-24" id="features">
      <Container>
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="heading-1 mb-4">Why Choose Fledgio?</h2>
          <p className="text-lg text-muted-foreground">
            Our training programs go beyond theory to provide practical skills that make an immediate impact.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div 
              key={feature.name} 
              className="bg-secondary/50 rounded-xl p-6 hover:shadow-md transition-all duration-300 border border-border"
            >
              <div className="h-12 w-12 rounded-lg bg-primary/10 flex items-center justify-center mb-5">
                <feature.icon className="h-6 w-6 text-primary" />
              </div>
              <h3 className="heading-3 mb-2">{feature.name}</h3>
              <p className="text-muted-foreground">{feature.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}
