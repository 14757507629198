import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Navbar } from "@/components/layout/Navbar";
import { Footer } from "@/components/layout/Footer";
import { Container } from "@/components/ui/Container";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ArrowLeft, Check, Clock, Calendar, MapPin, Users, Info } from "lucide-react";
import { getCourseBySlug, getCourseSchedules } from "@/lib/supabase";
import { Course, CourseSchedule } from "@/types";
import { toast } from "@/hooks/use-toast";
import { Card, CardContent } from "@/components/ui/card";

const categoryEmojis: Record<string, string> = {
  rust: "🦀",
  python: "🐍",
  cloud: "🐳",
  kubernetes: "🐳",
  docker: "🐳",
};

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const defaultTopics = {
  rust: ["Rust Syntax and Basics", "Ownership and Borrowing", "Structs and Enums", "Error Handling"],
  python: ["Python Basics", "Data Structures", "Functions and Modules", "Object-Oriented Programming"],
  cloud: ["Cloud Fundamentals", "Containers", "Orchestration", "Microservices"],
  kubernetes: ["Kubernetes Architecture", "Deployments", "Services", "ConfigMaps and Secrets"],
  docker: ["Docker Basics", "Dockerfiles", "Docker Compose", "Container Orchestration"],
};

const defaultPrerequisites = [
  "Basic programming experience",
  "Command line familiarity",
  "Understanding of basic CS concepts"
];

const CourseDetail = () => {
  const { slug } = useParams<{ slug: string }>();
  const [course, setCourse] = useState<Course | null>(null);
  const [schedules, setSchedules] = useState<CourseSchedule[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    async function fetchCourseData() {
      if (!slug) {
        setError("Course not found");
        setLoading(false);
        return;
      }

      try {
        const courseData = await getCourseBySlug(slug);
        
        if (!courseData) {
          setError("Course not found");
          setLoading(false);
          return;
        }
        
        setCourse(courseData);
        
        if (courseData.id) {
          const schedulesData = await getCourseSchedules(courseData.id);
          if (schedulesData) {
            setSchedules(schedulesData);
          }
        }
        
        setLoading(false);
      } catch (err) {
        console.error("Error fetching course details:", err);
        setError("Failed to load course details");
        setLoading(false);
        toast({
          title: "Error",
          description: "Failed to load course details. Please try again later.",
          variant: "destructive",
        });
      }
    }

    fetchCourseData();
  }, [slug]);

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow pt-36 md:pt-48 flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary mx-auto"></div>
            <p className="mt-4 text-muted-foreground">Loading course details...</p>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  if (error || !course) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow pt-36 md:pt-48">
          <Container className="py-12 text-center">
            <h1 className="heading-1 mb-4">Course Not Found</h1>
            <p className="text-muted-foreground mb-6">
              We couldn't find the course you're looking for.
            </p>
            <Button asChild>
              <Link to="/courses">
                <ArrowLeft className="mr-2 h-4 w-4" />
                Back to Courses
              </Link>
            </Button>
          </Container>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow pt-36 md:pt-48">
        <div className="bg-gray-50 border-b border-gray-200 py-12">
          <Container>
            <div className="flex items-center space-x-2 mb-4">
              <Link to="/courses" className="text-muted-foreground hover:text-primary">
                <ArrowLeft className="h-4 w-4 inline mr-1" />
                Back to Courses
              </Link>
              <span className="text-muted-foreground">/</span>
              <span className="text-muted-foreground capitalize">{course.category}</span>
            </div>
            
            <div className="flex flex-col md:flex-row md:items-center justify-between gap-6">
              <div>
                <div className="flex items-center space-x-2 mb-2">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-lite-blue bg-opacity-10 text-lite-blue">
                    {course.level.charAt(0).toUpperCase() + course.level.slice(1)}
                  </span>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize" 
                    style={{
                      backgroundColor: course.category === 'rust' ? 'rgba(244, 67, 54, 0.1)' : 
                                      course.category === 'python' ? 'rgba(76, 175, 80, 0.1)' : 
                                      'rgba(43, 149, 214, 0.1)',
                      color: course.category === 'rust' ? '#F44336' : 
                             course.category === 'python' ? '#4CAF50' : 
                             '#2B95D6'
                    }}
                  >
                    <span className="mr-1">
                      {categoryEmojis[course.category as keyof typeof categoryEmojis]}
                    </span>
                    {course.category}
                  </span>
                </div>
                <h1 className="heading-1 mb-2">{course.title}</h1>
                <p className="text-lg text-muted-foreground max-w-3xl">
                  {course.description}
                </p>
              </div>
              
              <div className="flex flex-col items-center bg-white rounded-lg shadow-sm border border-gray-100 p-6 min-w-52">
                <div className="text-3xl font-bold mb-2">£{course.price.toLocaleString()}</div>
                <div className="flex items-center text-sm text-muted-foreground mb-4">
                  <Clock className="h-4 w-4 mr-1" />
                  <span>{course.duration}</span>
                </div>
                <Button asChild className="w-full">
                  <a href="#schedules">View Schedules</a>
                </Button>
              </div>
            </div>
          </Container>
        </div>
        
        <Container className="py-12">
          <Tabs defaultValue="overview">
            <TabsList className="mb-8">
              <TabsTrigger value="overview">Overview</TabsTrigger>
              <TabsTrigger value="curriculum">Curriculum</TabsTrigger>
              <TabsTrigger value="schedules" id="schedules">Schedules</TabsTrigger>
            </TabsList>
            
            <TabsContent value="overview" className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="md:col-span-2 space-y-6">
                  <div>
                    <h2 className="heading-2 mb-4">Course Description</h2>
                    <div className="prose max-w-none">
                      {course.longDescription ? (
                        <div dangerouslySetInnerHTML={{ __html: course.longDescription }} />
                      ) : (
                        <p>{course.description}</p>
                      )}
                    </div>
                  </div>
                  
                  <div>
                    <h2 className="heading-2 mb-4">What You'll Learn</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                      {course.topics.map((topic: string) => (
                        <div key={topic} className="flex items-start">
                          <Check className="h-5 w-5 text-primary mr-2 flex-shrink-0 mt-0.5" />
                          <div dangerouslySetInnerHTML={{ __html: topic }} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                
                <div className="space-y-6">
                  <div className="bg-gray-50 rounded-lg p-6 border border-gray-100">
                    <h3 className="font-semibold text-lg mb-4">Course Details</h3>
                    <ul className="space-y-4">
                      <li className="flex items-start">
                        <Clock className="h-5 w-5 text-muted-foreground mr-3 flex-shrink-0 mt-0.5" />
                        <div>
                          <div className="font-medium">Duration</div>
                          <div className="text-sm text-muted-foreground">{course.duration}</div>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <Users className="h-5 w-5 text-muted-foreground mr-3 flex-shrink-0 mt-0.5" />
                        <div>
                          <div className="font-medium">Level</div>
                          <div className="text-sm text-muted-foreground capitalize">{course.level}</div>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <Info className="h-5 w-5 text-muted-foreground mr-3 flex-shrink-0 mt-0.5" />
                        <div>
                          <div className="font-medium">Prerequisites</div>
                          <ul className="text-sm text-muted-foreground list-disc pl-4 mt-1">
                            {course.prerequisites.map((prereq: string) => (
                              <li key={prereq}>{prereq}</li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  
                  <div className="bg-white rounded-lg p-6 border border-gray-200">
                    <h3 className="font-semibold text-lg mb-4">Need a Custom Course?</h3>
                    <p className="text-sm text-muted-foreground mb-4">
                      We offer tailored corporate training programs designed to meet your team's specific needs.
                    </p>
                    <Button asChild className="w-full">
                      <Link to="/contact">Contact Us</Link>
                    </Button>
                  </div>
                </div>
              </div>
            </TabsContent>
            
            <TabsContent value="curriculum">
              <div className="space-y-6">
                <h2 className="heading-2 mb-4">Course Curriculum</h2>
                
                {course.curriculum ? (
                  <Card className="bg-white">
                    <CardContent className="p-6">
                      <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: course.curriculum }} />
                    </CardContent>
                  </Card>
                ) : (
                  <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                    {course.topics.map((topic: string, index: number) => (
                      <div 
                        key={topic}
                        className={`p-4 ${index !== course.topics.length - 1 ? 'border-b border-gray-200' : ''}`}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center mr-3">
                              <span className="font-medium text-primary">{index + 1}</span>
                            </div>
                            <div className="font-medium" dangerouslySetInnerHTML={{ __html: topic }} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                
                <div className="bg-gray-50 rounded-lg p-6 mt-8">
                  <h3 className="font-semibold text-lg mb-2">Need a Different Curriculum?</h3>
                  <p className="text-muted-foreground mb-4">
                    We can customize our curriculum to match your team's experience level and specific learning goals.
                  </p>
                  <Button asChild variant="outline">
                    <Link to="/contact">Contact Us for Custom Curriculum</Link>
                  </Button>
                </div>
              </div>
            </TabsContent>
            
            <TabsContent value="schedules">
              <div className="space-y-6">
                <h2 className="heading-2 mb-4">Upcoming Course Dates</h2>
                
                {schedules.length === 0 ? (
                  <div className="bg-gray-50 rounded-lg p-8 text-center">
                    <p className="text-muted-foreground mb-4">
                      No upcoming schedules available for this course.
                    </p>
                    <Button asChild variant="outline">
                      <Link to="/contact">Request Schedule</Link>
                    </Button>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-4">
                    {schedules.map((schedule) => (
                      <div 
                        key={schedule.id}
                        className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-all duration-300"
                      >
                        <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                          <div className="md:col-span-2">
                            <div className="flex items-center mb-2">
                              <Calendar className="h-5 w-5 text-primary mr-2" />
                              <span className="font-medium">
                                {formatDate(schedule.startDate)} - {formatDate(schedule.endDate)}
                              </span>
                            </div>
                            <div className="flex items-start">
                              <MapPin className="h-5 w-5 text-muted-foreground mr-2 mt-0.5" />
                              <span>{schedule.location}</span>
                            </div>
                          </div>
                          
                          <div>
                            <div className="text-sm text-muted-foreground mb-1">Price</div>
                            <div className="font-bold">£{schedule.price.toLocaleString()}</div>
                          </div>
                          
                          <div>
                            <div className="text-sm text-muted-foreground mb-1">Availability</div>
                            <div className="flex items-center">
                              <span className="font-medium">
                                {schedule.availableSeats - schedule.bookedSeats} spots left
                              </span>
                              <span className="text-xs text-muted-foreground ml-2">
                                (of {schedule.availableSeats})
                              </span>
                            </div>
                          </div>
                          
                          <div className="flex items-center">
                            <Button asChild className="w-full">
                              <Link to={`/booking/${schedule.id}`}>Book Now</Link>
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                
                <div className="bg-gray-50 rounded-lg p-6 mt-8">
                  <h3 className="font-semibold text-lg mb-2">Need a Different Date?</h3>
                  <p className="text-muted-foreground mb-4">
                    We can arrange custom dates for corporate training or organize additional public sessions on demand.
                  </p>
                  <Button asChild variant="outline">
                    <Link to="/contact">Contact Us for Custom Scheduling</Link>
                  </Button>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default CourseDetail;
