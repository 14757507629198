
import { useState } from "react";
import { Navbar } from "@/components/layout/Navbar";
import { Footer } from "@/components/layout/Footer";
import { Container } from "@/components/ui/Container";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { MapPin, Phone, Mail, Send, Loader2, Linkedin, Youtube } from "lucide-react";
import { toast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    subject: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!privacyChecked) {
      toast({
        title: "Error",
        description: "Please agree to the privacy policy before submitting.",
        variant: "destructive",
      });
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // Save enquiry to database first
      const { error: dbError } = await supabase
        .from('enquiry')
        .insert([{
          name: formData.name,
          email: formData.email,
          company: formData.company,
          message: `${formData.subject}\n\n${formData.message}`,
        }]);
        
      if (dbError) throw new Error(dbError.message);

      // Also send email via the function for redundancy
      const { data, error } = await supabase.functions.invoke("send-contact-email", {
        body: formData,
      });

      if (error) {
        console.error("Function error but enquiry saved:", error);
        // Don't throw here since the enquiry was saved in database
      }

      toast({
        title: "Success",
        description: "Your message has been sent! We'll get back to you soon.",
      });
      
      setFormData({
        name: "",
        email: "",
        company: "",
        subject: "",
        message: "",
      });
      setPrivacyChecked(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast({
        title: "Error",
        description: "Failed to send message. Please try again later.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow pt-36 md:pt-48">
        <div className="bg-gray-50 border-b border-gray-200 py-12">
          <Container>
            <div className="max-w-3xl">
              <h1 className="heading-1 mb-4">Contact Us</h1>
              <p className="text-lg text-muted-foreground">
                Have questions about our courses or want to schedule a corporate training? 
                We're here to help.
              </p>
            </div>
          </Container>
        </div>
        
        <Container className="py-12">
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-12">
            <div className="lg:col-span-2">
              <div className="bg-white rounded-xl border border-gray-100 shadow-sm p-6 sticky top-24">
                <h2 className="heading-2 mb-6">Get in Touch</h2>
                
                <div className="space-y-6">
                  <div className="flex items-start">
                    <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center mr-4 flex-shrink-0">
                      <MapPin className="h-6 w-6 text-primary" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg mb-1">Head Office</h3>
                      <p className="text-muted-foreground">
                        Fledgio Limited<br />
                        86-90 Paul Street<br />
                        London, EC2A 4NE<br />
                        United Kingdom
                      </p>
                    </div>
                  </div>
                  
                  <div className="flex items-start">
                    <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center mr-4 flex-shrink-0">
                      <Phone className="h-6 w-6 text-primary" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg mb-1">Call Us</h3>
                      <p className="text-muted-foreground">
                        <a href="tel:+44-20-4525-9691" className="hover:text-primary">
                          +44 (0) 20 4525 9691
                        </a>
                        <br />
                        Monday - Friday, 9am - 5pm GMT
                      </p>
                    </div>
                  </div>
                  
                  <div className="flex items-start">
                    <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center mr-4 flex-shrink-0">
                      <Mail className="h-6 w-6 text-primary" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg mb-1">Email Us</h3>
                      <p className="text-muted-foreground">
                        <a href="mailto:info@fledgio.com" className="hover:text-primary">
                          info@fledgio.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="mt-8 pt-8 border-t border-gray-200">
                  <h3 className="font-semibold text-lg mb-4">Connect With Us</h3>
                  <div className="flex space-x-4">
                    <a href="https://linkedin.com" aria-label="LinkedIn" className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center hover:bg-primary/20 transition-colors">
                      <Linkedin className="h-5 w-5 text-primary" />
                    </a>
                    <a href="https://medium.com" aria-label="Medium" className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center hover:bg-primary/20 transition-colors">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-5 w-5 text-primary">
                        <path d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"></path>
                        <path d="M8 9l4 4 4-4"></path>
                        <path d="M8 12h8"></path>
                        <path d="M8 15h8"></path>
                      </svg>
                    </a>
                    <a href="https://bsky.app" aria-label="Bluesky" className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center hover:bg-primary/20 transition-colors">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-5 w-5 text-primary">
                        <path d="M8 16s1.5-2 4-2 4 2 4 2"></path>
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="m9 10 1.5-1.5a2.121 2.121 0 0 1 3 0L15 10"></path>
                      </svg>
                    </a>
                    <a href="https://youtube.com" aria-label="YouTube" className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center hover:bg-primary/20 transition-colors">
                      <Youtube className="h-5 w-5 text-primary" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="lg:col-span-3">
              <div className="bg-white rounded-xl border border-gray-100 shadow-sm p-6">
                <h2 className="heading-2 mb-6">Send Us a Message</h2>
                
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <Label htmlFor="name">Full Name</Label>
                      <Input
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="John Doe"
                        required
                      />
                    </div>
                    
                    <div className="space-y-2">
                      <Label htmlFor="email">Email Address</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="john.doe@example.com"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <Label htmlFor="company">Company (Optional)</Label>
                      <Input
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        placeholder="Your company"
                      />
                    </div>
                    
                    <div className="space-y-2">
                      <Label htmlFor="subject">Subject</Label>
                      <Input
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        placeholder="How can we help you?"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <Label htmlFor="message">Message</Label>
                    <Textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Please describe your inquiry..."
                      rows={6}
                      className="resize-none"
                      required
                    />
                  </div>
                  
                  <div className="flex items-center">
                    <input
                      id="privacy"
                      type="checkbox"
                      required
                      checked={privacyChecked}
                      onChange={(e) => setPrivacyChecked(e.target.checked)}
                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                    />
                    <label htmlFor="privacy" className="ml-2 block text-sm">
                      I agree to the{" "}
                      <a href="/privacy" className="text-primary hover:underline">
                        Privacy Policy
                      </a>
                      {" "}and consent to being contacted.
                    </label>
                  </div>
                  
                  <Button type="submit" className="w-full md:w-auto" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Sending Message...
                      </>
                    ) : (
                      <>
                        <Send className="mr-2 h-4 w-4" />
                        Send Message
                      </>
                    )}
                  </Button>
                </form>
              </div>
              
              <div className="mt-12">
                <h2 className="heading-2 mb-6">Frequently Asked Questions</h2>
                
                <div className="space-y-4">
                  <div className="bg-white rounded-lg border border-gray-100 shadow-sm p-6">
                    <h3 className="font-semibold text-lg mb-2">Do you offer custom corporate training?</h3>
                    <p className="text-muted-foreground">
                      Yes, we specialize in tailored corporate training programs. We'll work with your team to understand your specific needs and develop a customized curriculum. Contact us for a consultation.
                    </p>
                  </div>
                  
                  <div className="bg-white rounded-lg border border-gray-100 shadow-sm p-6">
                    <h3 className="font-semibold text-lg mb-2">Where are your courses held?</h3>
                    <p className="text-muted-foreground">
                      Our public courses are typically held at our London training center. We also offer remote live training and on-site corporate training at your location.
                    </p>
                  </div>
                  
                  <div className="bg-white rounded-lg border border-gray-100 shadow-sm p-6">
                    <h3 className="font-semibold text-lg mb-2">How can I schedule a course for my team?</h3>
                    <p className="text-muted-foreground">
                      Fill out the contact form on this page or email us at training@fledgio.com with details about your team size, desired topics, and preferred timeline. We'll get back to you with available options.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        
        <div className="py-12 bg-gray-50">
          <Container>
            <h2 className="heading-2 mb-6 text-center">Visit Our Training Center</h2>
            <div className="rounded-xl overflow-hidden shadow-lg border border-gray-100 h-96">
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.7347842774295!2d-0.10069908923633687!3d51.375645272206174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760733e9bb38e7%3A0x87aecb3f9dece959!2sCroydon%20CR9%204LT%2C%20UK!5e0!3m2!1sen!2suk!4v1691459132201!5m2!1sen!2suk" 
                width="100%" 
                height="100%" 
                style={{ border: 0 }} 
                allowFullScreen 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                title="Fledgio Training Center Location"
              ></iframe>
            </div>
          </Container>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
