
import { Container } from "@/components/ui/Container";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ArrowRight, Award } from "lucide-react";

export const AboutHero = () => {
  return (
    <div className="bg-gray-50 border-b border-gray-200 py-16">
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <h1 className="heading-1 mb-6">About Fledgio</h1>
            <p className="text-lg text-muted-foreground mb-6">
              We are a specialized technical training company focused on delivering 
              high-quality, hands-on education in Rust Programming, Python for Data & AI, 
              and Multi-Cloud Solutions.
            </p>
            <p className="text-lg text-muted-foreground mb-8">
              Founded by industry experts with decades of experience, we're committed to 
              teaching not just theory, but practical, real-world skills that make an 
              immediate impact.
            </p>
            <Button asChild>
              <Link to="/contact">
                Contact Us
                <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
            </Button>
          </div>
          <div className="relative">
            <div className="aspect-video relative rounded-xl overflow-hidden shadow-xl">
              <img 
                src="https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-4.0.3&auto=format&fit=crop&w=1740&q=80" 
                alt="Team collaborating" 
                className="w-full h-full object-cover"
              />
            </div>
            <div className="absolute -bottom-6 -left-6 bg-white rounded-lg shadow-lg p-4 max-w-xs">
              <div className="flex items-center space-x-4">
                <div className="h-12 w-12 rounded-full bg-lite-blue/10 flex items-center justify-center">
                  <Award className="h-6 w-6 text-lite-blue" />
                </div>
                <div>
                  <div className="font-bold">25+ Years</div>
                  <div className="text-sm text-muted-foreground">Industry Experience</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
