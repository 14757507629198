
import { Container } from "@/components/ui/Container";
import { BookOpen, Briefcase, Users } from "lucide-react";

export const PhilosophySection = () => {
  const philosophies = [
    {
      icon: <svg className="h-6 w-6 text-lite-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
      </svg>,
      title: "Practical Solutions",
      description: "Our training focuses on building real-world solutions that you can immediately apply.",
      bgClass: "bg-lite-blue/10",
      iconClass: "text-lite-blue"
    },
    {
      icon: <svg className="h-6 w-6 text-lite-green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
        <polyline points="7.5 4.21 12 6.81 16.5 4.21"></polyline>
        <polyline points="7.5 19.79 7.5 14.6 3 12"></polyline>
        <polyline points="21 12 16.5 14.6 16.5 19.79"></polyline>
        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
        <line x1="12" y1="22.08" x2="12" y2="12"></line>
      </svg>,
      title: "Code-First Approach",
      description: "We emphasize infrastructure-as-code, configuration-as-code, and security-as-code principles.",
      bgClass: "bg-lite-green/10",
      iconClass: "text-lite-green"
    },
    {
      icon: <svg className="h-6 w-6 text-lite-red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
      </svg>,
      title: "Security First",
      description: "We teach security from the ground up, not as an afterthought, ensuring robust solutions.",
      bgClass: "bg-lite-red/10",
      iconClass: "text-lite-red"
    },
    {
      icon: <svg className="h-6 w-6 text-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polyline points="4 17 10 11 4 5"></polyline>
        <line x1="12" y1="19" x2="20" y2="19"></line>
      </svg>,
      title: "Performance Optimization",
      description: "Learn how to build systems that are not just functional, but also performant and efficient.",
      bgClass: "bg-primary/10",
      iconClass: "text-primary"
    }
  ];

  return (
    <section className="py-16">
      <Container>
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="heading-1 mb-4">Our Philosophy for Learning</h2>
          <p className="text-lg text-muted-foreground">
            We believe in practical, hands-on training that prepares you for real-world challenges.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {philosophies.map((philosophy, index) => (
            <div key={index} className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
              <div className={`h-12 w-12 rounded-full ${philosophy.bgClass} flex items-center justify-center mb-4`}>
                {philosophy.icon}
              </div>
              <h3 className="font-semibold text-lg mb-2">{philosophy.title}</h3>
              <p className="text-muted-foreground">
                {philosophy.description}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};
