
import { Container } from "@/components/ui/Container";

export const DataChefSection = () => {
  return (
    <section className="py-16 bg-gray-50">
      <Container>
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="heading-1 mb-4">Meet The Data Chef</h2>
          <p className="text-lg text-muted-foreground">
            The expert behind Fledgio
          </p>
        </div>
        
        <div className="max-w-4xl mx-auto">
          <div className="bg-white rounded-xl p-8 shadow-sm border border-gray-100">
            <div className="flex flex-col md:flex-row gap-8 items-center md:items-start">
              <div className="w-full md:w-1/3">
                <div className="aspect-square rounded-xl overflow-hidden mb-6">
                  <img 
                    src="/lovable-uploads/dba3bcbe-b51d-4e27-9a74-3701b90cad4a.png" 
                    alt="TheDataChef" 
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="w-full md:w-2/3">
                <h3 className="font-semibold text-xl mb-1">Lawrence "TheDataChef" Freeman</h3>
                <p className="text-primary mb-5">Founder, and Head Chef</p>
                <p className="text-muted-foreground mb-6">
                  With 25+ years in the industry, Lawrence has expertly trained thousands, and developed corporate and consulting training programs benefiting thousands of people now veterans in their specialist software, cloud and data fields. His background in data, infrastructure and cyber security brings to the fore his focus for optimal software solutions to built as-code and with elegance from the ground up.
                </p>
                <div className="flex space-x-5 text-muted-foreground">
                  <a href="https://www.linkedin.com/in/thedatachef/" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-5 h-5">
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                      <rect x="2" y="9" width="4" height="12"></rect>
                      <circle cx="4" cy="4" r="2"></circle>
                    </svg>
                  </a>
                  <a href="https://www.youtube.com/@thedatachef" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-5 h-5">
                      <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                      <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                    </svg>
                  </a>
                  <a href="https://medium.com/@thedatachef" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors">
                    <img 
                      src="/lovable-uploads/5a5cdecc-01e0-4524-90ac-33b226454d1b.png" 
                      alt="Medium" 
                      className="w-5 h-5"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
