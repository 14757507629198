
import { ArrowRight, BarChart, Clock } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Course } from "@/types";

interface CourseCardProps {
  course: Course;
  getCategoryEmoji: (category: string) => string;
}

export const CourseCard = ({ course, getCategoryEmoji }: CourseCardProps) => {
  return (
    <Link 
      to={`/courses/${course.slug}`}
      className="block bg-white rounded-xl overflow-hidden border border-gray-100 shadow-sm hover:shadow-md transition-all duration-300"
    >
      <div className="aspect-video relative">
        <img
          src={course.imageUrl || "https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"}
          alt={course.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-4 left-4 bg-white/90 backdrop-blur-sm rounded-full px-3 py-1 text-sm font-medium flex items-center">
          <span className="mr-1">
            {getCategoryEmoji(course.category)}
          </span>
          <span className="capitalize">{course.category}</span>
        </div>
        <div className="absolute top-4 right-4 bg-white/90 backdrop-blur-sm rounded-full px-3 py-1 text-sm font-medium flex items-center">
          <span className="capitalize">{course.level}</span>
        </div>
      </div>
      
      <div className="p-6">
        <h3 className="heading-3 mb-2">{course.title}</h3>
        <p className="text-muted-foreground mb-4 line-clamp-2">
          {course.description}
        </p>
        
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center text-sm text-muted-foreground">
            <Clock className="h-4 w-4 mr-1" />
            <span>{course.duration}</span>
          </div>
          <div className="flex items-center text-sm font-medium">
            <BarChart className="h-4 w-4 mr-1" />
            <span>{course.topics?.length || 0} Topics</span>
          </div>
        </div>
        
        <div className="flex items-center justify-between mt-6">
          <div>
            <div className="text-lg font-bold">£{course.price.toLocaleString()}</div>
            <div className="text-xs text-muted-foreground">ex. VAT</div>
          </div>
          <Button size="sm" className="pointer-events-none">
            View Details
            <ArrowRight className="ml-1 h-4 w-4" />
          </Button>
        </div>
      </div>
    </Link>
  );
};
