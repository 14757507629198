
import { type Course } from '@/types';
import { supabase } from './utils';

export async function getAllCourses(): Promise<Course[] | null> {
  try {
    console.log('Fetching all courses from Supabase...');
    
    const { data, error } = await supabase
      .from('course')
      .select('*')
      .order('display_order', { ascending: true, nullsFirst: false })
      .order('code');
      
    if (error) {
      console.error('Error fetching courses:', error);
      return null;
    }
    
    console.log('Fetched courses:', data);
    
    if (!data) {
      return null;
    }
    
    // Map the database column names to our frontend model names
    const formattedCourses = data.map(course => ({
      id: course.course_id.toString(),
      title: course.code || '',
      slug: course.code?.toLowerCase().replace(/\s+/g, '-') || '',
      description: course.course_description || '',
      longDescription: course.overview || '',
      category: course.category || course.tech || '', // Now prioritizing the category column
      level: course.difficulty || 'beginner',
      price: course.price || 0,
      duration: `${course.days || 0} days`,
      curriculum: course.curriculum || '', 
      topics: course.learning_outcomes ? parseTopics(course.learning_outcomes) : [],
      prerequisites: [],
      imageUrl: course.image_url || null,
      createdAt: course.created_at,
      updatedAt: course.created_at,
    }));
    
    return formattedCourses;
  } catch (error) {
    console.error('Error in getAllCourses:', error);
    return null;
  }
}

export async function getCourseBySlug(slug: string): Promise<Course | null> {
  try {
    console.log(`Fetching course with slug "${slug}" from Supabase...`);
    
    // Get course by matching the lowercase version of code to the slug
    const { data, error } = await supabase
      .from('course')
      .select('*')
      .filter('code', 'ilike', `%${slug.replace(/-/g, ' ')}%`)
      .single();
      
    if (error) {
      console.error('Error fetching course by slug:', error);
      return null;
    }
    
    console.log('Fetched course:', data);
    
    if (!data) {
      return null;
    }
    
    // Map to Course type
    return {
      id: data.course_id.toString(),
      title: data.code || '',
      slug: (data.code || '').toLowerCase().replace(/\s+/g, '-'),
      description: data.course_description || '',
      longDescription: data.overview || '',
      category: data.category || data.tech || '', // Now prioritizing the category column
      level: data.difficulty || 'beginner',
      price: data.price || 0,
      duration: `${data.days || 0} days`,
      curriculum: data.curriculum || '',
      topics: data.learning_outcomes ? parseTopics(data.learning_outcomes) : [],
      prerequisites: [],
      imageUrl: data.image_url || null,
      createdAt: data.created_at,
      updatedAt: data.created_at,
    };
  } catch (error) {
    console.error('Error in getCourseBySlug:', error);
    return null;
  }
}

// Helper function to parse learning outcomes into topics array
function parseTopics(learningOutcomes: string): string[] {
  // Check if it's already in JSON format
  try {
    const parsed = JSON.parse(learningOutcomes);
    if (Array.isArray(parsed)) {
      return parsed;
    }
  } catch (e) {
    // Not valid JSON, continue with other parsing methods
  }
  
  // Try to split by newlines or semicolons
  if (learningOutcomes.includes('\n')) {
    return learningOutcomes.split('\n').map(item => item.trim()).filter(Boolean);
  }
  
  if (learningOutcomes.includes(';')) {
    return learningOutcomes.split(';').map(item => item.trim()).filter(Boolean);
  }
  
  // If it's a single string, return as single item array
  return [learningOutcomes];
}

export async function getCourseCategories(): Promise<string[] | null> {
  try {
    console.log('Fetching course categories from Supabase...');
    
    const { data, error } = await supabase
      .from('course')
      .select('category')
      .order('category');
      
    if (error) {
      console.error('Error fetching course categories:', error);
      return null;
    }
    
    // Get unique category values and ensure they are strings
    const categories = data
      .map(item => item.category)
      .filter((category): category is string => 
        typeof category === 'string' && category !== null && category !== undefined
      );
      
    // Using Set to get unique values and converting back to string[]
    const uniqueCategories = Array.from(new Set(categories));
    console.log('Fetched unique categories:', uniqueCategories);
    return uniqueCategories;
  } catch (error) {
    console.error('Error in getCourseCategories:', error);
    return null;
  }
}
