import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Navbar } from "@/components/layout/Navbar";
import { Footer } from "@/components/layout/Footer";
import { Container } from "@/components/ui/Container";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { Calendar, Clock, MapPin, CreditCard } from "lucide-react";
import { toast } from "@/hooks/use-toast";
import { useQuery } from "@tanstack/react-query";
import { getScheduleById } from "@/lib/supabase";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { createBooking } from "@/lib/supabase/bookings";

const formSchema = z.object({
  title: z.string().min(2, "Title is required"),
  name: z.string().min(2, "Full name is required"),
  email: z.string().email("Valid email is required"),
  phone: z.string().min(5, "Phone number is required"),
  address_1: z.string().min(2, "Address is required"),
  address_2: z.string().optional(),
  city: z.string().min(2, "City is required"),
  postal_code: z.string().min(2, "Postal code is required"),
  country: z.string().min(2, "Country is required"),
});

type FormValues = z.infer<typeof formSchema>;

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const VAT_RATE = 0.20;

const Booking = () => {
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: schedule, isLoading, error } = useQuery({
    queryKey: ['schedule', scheduleId],
    queryFn: async () => {
      if (!scheduleId) return null;
      
      console.log(`Fetching schedule details for ID: ${scheduleId}`);
      
      const scheduleData = await getScheduleById(scheduleId);
      
      if (!scheduleData) {
        throw new Error("Schedule not found");
      }
      
      console.log("Schedule found:", scheduleData);
      return scheduleData;
    },
    retry: 1,
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      name: "",
      email: "",
      phone: "",
      address_1: "",
      address_2: "",
      city: "",
      postal_code: "",
      country: "United Kingdom",
    },
  });

  const onSubmit = async (values: FormValues) => {
    try {
      setIsSubmitting(true);
      
      if (!schedule) {
        toast({
          title: "Error",
          description: "Course schedule details not found",
          variant: "destructive",
        });
        setIsSubmitting(false);
        return;
      }
      
      console.log("Submitting booking with values:", values);
      
      const result = await createBooking({
        customer: {
          title: values.title,
          name: values.name,
          email: values.email,
          phone: values.phone,
          address_1: values.address_1,
          address_2: values.address_2,
          city: values.city,
          postal_code: values.postal_code,
          country: values.country,
        },
        scheduleId: schedule.id,
        price: schedule.price,
      });
      
      if (!result || !result.checkoutUrl) {
        throw new Error("Failed to create booking");
      }
      
      console.log("Booking created successfully, redirecting to checkout:", result);
      
      // Open Stripe checkout in a new tab to avoid CSP issues
      window.open(result.checkoutUrl, "_blank");
      
      // Also navigate to the confirmation page with a pending status
      navigate('/booking-confirmation?status=pending');
      
    } catch (error: any) {
      console.error("Error creating booking:", error);
      toast({
        title: "Booking Failed",
        description: error.message || "There was an error processing your booking",
        variant: "destructive",
      });
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow pt-36 md:pt-48">
          <Container>
            <div className="max-w-3xl mx-auto">
              <Skeleton className="h-8 w-3/4 mb-4" />
              <Skeleton className="h-4 w-1/2 mb-8" />
              <div className="space-y-6">
                <Skeleton className="h-24 w-full" />
                <Skeleton className="h-80 w-full" />
              </div>
            </div>
          </Container>
        </main>
        <Footer />
      </div>
    );
  }

  if (error || !schedule) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow pt-36 md:pt-48">
          <Container>
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="heading-1 mb-4">Schedule Not Found</h1>
              <p className="text-muted-foreground mb-6">
                The course schedule you're looking for could not be found.
              </p>
              <Button onClick={() => navigate('/courses')}>
                Back to Courses
              </Button>
            </div>
          </Container>
        </main>
        <Footer />
      </div>
    );
  }

  const basePrice = schedule?.price || 0;
  const vatAmount = basePrice * VAT_RATE;
  const totalPrice = basePrice + vatAmount;

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow pt-36 md:pt-48 pb-20">
        <Container>
          <div className="max-w-3xl mx-auto">
            <h1 className="heading-1 mb-2">Book Your Seat</h1>
            <p className="text-muted-foreground mb-8">
              Complete the form below to reserve your place on this course.
            </p>
            
            <div className="bg-muted p-6 rounded-lg mb-8">
              <h2 className="text-xl font-semibold mb-4">Course Details</h2>
              <div className="space-y-3">
                <div className="flex items-start">
                  <div className="flex-shrink-0 mr-3 mt-1">
                    <Calendar className="h-5 w-5 text-primary" />
                  </div>
                  <div>
                    <div className="font-medium">{schedule?.course?.title || 'Course'}</div>
                    <div className="text-muted-foreground">
                      {schedule?.startDate ? formatDate(schedule.startDate) : ''} - {schedule?.endDate ? formatDate(schedule.endDate) : ''}
                    </div>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 mr-3 mt-1">
                    <MapPin className="h-5 w-5 text-primary" />
                  </div>
                  <div>
                    <div className="font-medium">Location</div>
                    <div className="text-muted-foreground">{schedule?.location}</div>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 mr-3 mt-1">
                    <CreditCard className="h-5 w-5 text-primary" />
                  </div>
                  <div>
                    <div className="font-medium">Price</div>
                    <div className="text-muted-foreground">
                      <div>£{basePrice.toLocaleString()} (ex. VAT)</div>
                      <div className="text-xs mt-1">VAT (20%): £{vatAmount.toLocaleString()}</div>
                      <div className="font-medium mt-1">Total: £{totalPrice.toLocaleString()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Title</FormLabel>
                        <FormControl>
                          <Input placeholder="Mr/Mrs/Ms/Dr" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Full Name</FormLabel>
                        <FormControl>
                          <Input placeholder="John Smith" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email Address</FormLabel>
                        <FormControl>
                          <Input type="email" placeholder="john@example.com" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Phone Number</FormLabel>
                        <FormControl>
                          <Input placeholder="+44 123 456 7890" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                
                <div className="space-y-4">
                  <h3 className="text-lg font-medium">Billing Address</h3>
                  
                  <FormField
                    control={form.control}
                    name="address_1"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Address Line 1</FormLabel>
                        <FormControl>
                          <Input placeholder="123 Main St" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="address_2"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Address Line 2 (optional)</FormLabel>
                        <FormControl>
                          <Input placeholder="Apt 4B" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <FormField
                      control={form.control}
                      name="city"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>City</FormLabel>
                          <FormControl>
                            <Input placeholder="London" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="postal_code"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Postal Code</FormLabel>
                          <FormControl>
                            <Input placeholder="EC1A 1BB" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="country"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Country</FormLabel>
                          <FormControl>
                            <Input placeholder="United Kingdom" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                
                <div className="pt-4">
                  <Button
                    type="submit"
                    size="lg"
                    className="w-full md:w-auto"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Processing..." : "Proceed to Payment"}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default Booking;
