
import { useEffect, useRef } from "react";
import * as d3 from "d3";

const RustRadarChart = () => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chartRef.current) return;

    // Clear any existing chart
    d3.select(chartRef.current).selectAll("*").remove();

    // Create container div for the legend and chart
    const container = d3.select(chartRef.current);

    // Create legend div
    const legendDiv = container.append("div")
      .attr("class", "legend")
      .attr("id", "legend");

    // Create SVG
    const svg = container.append("svg")
      .attr("width", 600)
      .attr("height", 600);

    const data = [
      {name: 'Rust', values: [5, 5, 5, 2, 5, 3]},
      {name: 'Python', values: [2, 2, 2, 5, 4, 5]},
      {name: 'Go', values: [3, 4, 4, 4, 4, 4]},
      {name: 'Java', values: [3, 4, 3, 4, 3, 5]},
      {name: 'Scala', values: [3, 4, 3, 3, 3, 3]},
      {name: 'Haskell', values: [1, 5, 4, 1, 3, 2]},
      {name: 'C / C++', values: [5, 1, 2, 2, 3, 5]},
      {name: 'Javascript', values: [2, 2, 2, 5, 4, 5]},
    ];

    const features = ['Control', 'Safety', 'Sustainability', 'Ease to Learn', 'Industry Admired', 'Industry Adopted'];

    const width = 600;
    const height = 600;

    const radius = (Math.min(width, height) / 2 - 60) * 0.8; // Scale down by 20%
    const center = { x: width / 2, y: height / 2 };

    const angleSlice = Math.PI * 2 / features.length;

    const rScale = d3.scaleLinear()
      .domain([0, 5])
      .range([0, radius]);

    // Draw axis lines
    features.forEach((feature, i) => {
      const angle = angleSlice * i;
      const lineEnd = {
        x: center.x + rScale(5) * Math.cos(angle - Math.PI/2),
        y: center.y + rScale(5) * Math.sin(angle - Math.PI/2),
      };
      svg.append("line")
        .attr("x1", center.x)
        .attr("y1", center.y)
        .attr("x2", lineEnd.x)
        .attr("y2", lineEnd.y)
        .attr("stroke", "#ccc");

      svg.append("text")
        .attr("x", lineEnd.x)
        .attr("y", lineEnd.y)
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .text(feature);
    });

    // Draw grid circles
    const levels = 5;
    for (let level = 1; level <= levels; level++) {
      const r = radius * (level / levels);
      svg.append("circle")
        .attr("cx", center.x)
        .attr("cy", center.y)
        .attr("r", r)
        .attr("fill", "none")
        .attr("stroke", "#ddd");
    }

    const line = d3.lineRadial<{axis: string, value: number}>()
      .radius(d => rScale(d.value))
      .angle((d, i) => i * angleSlice)
      .curve(d3.curveLinearClosed); // Ensure closed path

    const colors = d3.scaleOrdinal(d3.schemeCategory10);

    const radarGroups = svg.append("g").attr("class", "radarGroups");

    function updateChart(selectedNames: string[]) {
      radarGroups.selectAll("path").remove();

      data.filter(d => selectedNames.includes(d.name)).forEach((lang) => {
        const points = lang.values.map((v, i) => ({axis: features[i], value: v}));

        radarGroups.append("path")
          .datum(points)
          .attr("transform", `translate(${center.x},${center.y})`)
          .attr("fill", colors(lang.name) as string)
          .attr("fill-opacity", 0.3)
          .attr("stroke", colors(lang.name) as string)
          .attr("stroke-width", 2)
          .attr("d", line as any);
      });
    }

    // Initial render with only Rust
    updateChart(['Rust']);

    // Create legend
    data.forEach(d => {
      const label = legendDiv.append("label");
      label.append("div")
        .attr("class", "color-box")
        .style("background-color", colors(d.name) as string);
      label.append("input")
        .attr("type", "checkbox")
        .attr("value", d.name)
        .property("checked", d.name === 'Rust')
        .on("change", function() {
          const selected = Array.from(
            document.querySelectorAll('#legend input:checked')
          ).map(input => (input as HTMLInputElement).value);
          updateChart(selected);
        });
      label.append("span").text(" " + d.name);
    });

    return () => {
      // Clean up
      if (chartRef.current) {
        d3.select(chartRef.current).selectAll("*").remove();
      }
    };
  }, []);

  return (
    <div 
      ref={chartRef} 
      className="radar-chart-container w-full flex flex-col items-center"
      style={{ minHeight: "600px" }}
    >
      {/* D3 will render the chart here */}
    </div>
  );
};

export default RustRadarChart;
