
import React from 'react';
import { Navbar } from "@/components/layout/Navbar";
import { Footer } from "@/components/layout/Footer";
import { Container } from "@/components/ui/Container";
import { SEOHead } from "@/components/seo/SEOHead";

const Dashboard = () => {
  // Create structured data for dashboard page
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Dashboard | Fledgio",
    "description": "User dashboard for Fledgio training platform."
  };

  return (
    <div className="min-h-screen flex flex-col">
      <SEOHead 
        title="Dashboard | Fledgio"
        description="Manage your Fledgio training courses and account settings."
        keywords="Fledgio Dashboard, My Courses, Account Settings"
        canonical="https://www.fledgio.com/dashboard"
        structuredData={structuredData}
      />
      <Navbar />
      <main className="flex-grow pt-36 md:pt-48">
        <div className="bg-gray-50 border-b border-gray-200 py-12">
          <Container>
            <div className="max-w-3xl">
              <h1 className="heading-1 mb-4">Dashboard</h1>
              <p className="text-lg text-muted-foreground">
                Manage your courses and account settings.
              </p>
            </div>
          </Container>
        </div>
        
        <Container className="py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="md:col-span-2">
              <h2 className="heading-2 mb-6">My Courses</h2>
              <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
                <p className="text-muted-foreground">You haven't enrolled in any courses yet.</p>
              </div>
            </div>
            
            <div>
              <h2 className="heading-2 mb-6">Account</h2>
              <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
                <p className="font-medium mb-1">Account Details</p>
                <p className="text-muted-foreground mb-4">Update your profile information.</p>
                <hr className="my-4" />
                <p className="font-medium mb-1">Preferences</p>
                <p className="text-muted-foreground">Manage notification settings.</p>
              </div>
            </div>
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default Dashboard;
