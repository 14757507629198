
import { type CourseSchedule } from '@/types';
import { supabase, mapStatus, formatDateString } from './utils';

export async function getUpcomingCourses(): Promise<CourseSchedule[] | null> {
  try {
    console.log('Fetching upcoming course schedules from Supabase...');
    
    const { data, error } = await supabase
      .from('course_schedule')
      .select(`
        *,
        course:course_id (
          course_id,
          code,
          tech,
          category,
          course_description,
          days,
          price
        )
      `)
      .eq('status', 'upcoming')  // Only fetch upcoming courses
      .order('start_date', { ascending: true });
      
    if (error) {
      console.error('Error fetching upcoming courses:', error);
      return null;
    }
    
    console.log('Fetched course schedules:', data);
    
    if (!data || data.length === 0) {
      console.log('No upcoming courses found');
      return [];
    }
    
    // Map the database column names to our frontend model
    const formattedSchedules = data.map(schedule => {
      try {
        // Calculate end date based on start date and course days
        const startDate = new Date(schedule.start_date || '');
        const days = schedule.course?.days || 0;
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + days - 1); // Subtract 1 for correct end date
        
        return {
          id: schedule.schedule_id?.toString() || '',
          courseId: schedule.course_id?.toString() || '',
          startDate: schedule.start_date || '',
          endDate: formatDateString(endDate),
          location: schedule.location || '',
          availableSeats: schedule.capacity || 0,
          bookedSeats: 0, // This might need to be calculated from bookings
          price: schedule.course?.price || 0,
          status: mapStatus(schedule.status),
          createdAt: schedule.created_at || new Date().toISOString(),
          updatedAt: schedule.created_at || new Date().toISOString(),
          course: {
            id: schedule.course?.course_id?.toString() || '',
            title: schedule.course?.code || '',
            slug: (schedule.course?.code || '').toLowerCase().replace(/\s+/g, '-'),
            category: schedule.course?.category || schedule.course?.tech || '', // Now prioritizing the category column
          }
        };
      } catch (itemError) {
        console.error('Error formatting schedule item:', itemError, schedule);
        return null;
      }
    }).filter(Boolean) as CourseSchedule[];
    
    console.log('Formatted schedules:', formattedSchedules);
    return formattedSchedules;
  } catch (error) {
    console.error('Error in getUpcomingCourses:', error);
    return null;
  }
}

export async function getCourseSchedules(courseId: string): Promise<CourseSchedule[] | null> {
  try {
    console.log(`Fetching schedules for course "${courseId}" from Supabase...`);
    
    // Convert courseId to number for database query
    const courseIdNum = parseInt(courseId, 10);
    
    if (isNaN(courseIdNum)) {
      console.error('Invalid course ID format:', courseId);
      return null;
    }
    
    const { data, error } = await supabase
      .from('course_schedule')
      .select(`
        *,
        course:course_id (
          course_id,
          code,
          tech,
          category,
          course_description,
          days,
          price
        )
      `)
      .eq('course_id', courseIdNum)
      .eq('status', 'upcoming')
      .order('start_date', { ascending: true });
      
    if (error) {
      console.error('Error fetching course schedules:', error);
      return null;
    }
    
    console.log('Fetched course schedules:', data);
    
    if (!data) {
      return null;
    }
    
    // Map to CourseSchedule type using same mapping as in getUpcomingCourses
    const formattedSchedules = data.map(schedule => {
      const startDate = new Date(schedule.start_date || '');
      const days = schedule.course?.days || 0;
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + days);
      
      return {
        id: schedule.schedule_id.toString(),
        courseId: schedule.course_id?.toString() || '',
        startDate: schedule.start_date || '',
        endDate: formatDateString(endDate),
        location: schedule.location || '',
        availableSeats: schedule.capacity || 0,
        bookedSeats: 0,
        price: schedule.course?.price || 0,
        status: mapStatus(schedule.status),
        createdAt: schedule.created_at,
        updatedAt: schedule.created_at,
        course: {
          id: schedule.course?.course_id.toString() || '',
          title: schedule.course?.code || '',
          slug: (schedule.course?.code || '').toLowerCase().replace(/\s+/g, '-'),
          category: schedule.course?.category || schedule.course?.tech || '', // Now prioritizing the category column
        }
      };
    });
    
    return formattedSchedules;
  } catch (error) {
    console.error('Error in getCourseSchedules:', error);
    return null;
  }
}

export async function getScheduleById(scheduleId: string): Promise<CourseSchedule | null> {
  try {
    console.log(`Fetching schedule with ID "${scheduleId}" from Supabase...`);
    
    // Convert scheduleId to number for database query
    const scheduleIdNum = parseInt(scheduleId, 10);
    
    if (isNaN(scheduleIdNum)) {
      console.error('Invalid schedule ID format:', scheduleId);
      return null;
    }
    
    const { data, error } = await supabase
      .from('course_schedule')
      .select(`
        *,
        course:course_id (
          course_id,
          code,
          tech,
          category,
          course_description,
          days,
          price
        )
      `)
      .eq('schedule_id', scheduleIdNum)
      .maybeSingle();
      
    if (error) {
      console.error('Error fetching schedule:', error);
      return null;
    }
    
    console.log('Fetched schedule:', data);
    
    if (!data) {
      return null;
    }
    
    // Map to CourseSchedule type 
    const startDate = new Date(data.start_date || '');
    const days = data.course?.days || 0;
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + days - 1);
    
    return {
      id: data.schedule_id.toString(),
      courseId: data.course_id?.toString() || '',
      startDate: data.start_date || '',
      endDate: formatDateString(endDate),
      location: data.location || '',
      availableSeats: data.capacity || 0,
      bookedSeats: 0,
      price: data.course?.price || 0,
      status: mapStatus(data.status),
      createdAt: data.created_at,
      updatedAt: data.created_at,
      course: {
        id: data.course?.course_id.toString() || '',
        title: data.course?.code || '',
        slug: (data.course?.code || '').toLowerCase().replace(/\s+/g, '-'),
        category: data.course?.category || data.course?.tech || '',
      }
    };
  } catch (error) {
    console.error('Error in getScheduleById:', error);
    return null;
  }
}
