
import { supabase } from '../utils';
import { BookingData } from './types';
import { toast } from '@/hooks/use-toast';

// UK VAT rate (20%)
const VAT_RATE = 0.20;

export async function createBooking(data: BookingData) {
  try {
    console.log('Creating booking with data:', data);
    
    // Calculate VAT
    const basePrice = data.price;
    const vatAmount = basePrice * VAT_RATE;
    const totalPrice = basePrice + vatAmount;
    
    // 1. First, create or get customer
    const { data: existingCustomers, error: customerFetchError } = await supabase
      .from('customer')
      .select('customer_id')
      .eq('email', data.customer.email)
      .maybeSingle();
      
    if (customerFetchError) {
      console.error('Error fetching existing customer:', customerFetchError);
      throw new Error('Failed to check existing customer');
    }
    
    let customerId;
    
    if (existingCustomers) {
      // Update existing customer
      customerId = existingCustomers.customer_id;
      
      console.log('Updating existing customer:', customerId);
      
      const { error: updateError } = await supabase
        .from('customer')
        .update({
          title: data.customer.title,
          name: data.customer.name,
          phone: data.customer.phone,
          address_1: data.customer.address_1,
          address_2: data.customer.address_2 || null,
          city: data.customer.city,
          postal_code: data.customer.postal_code,
          country: data.customer.country,
        })
        .eq('customer_id', customerId);
        
      if (updateError) {
        console.error('Error updating customer:', updateError);
        throw new Error('Failed to update customer details');
      }
    } else {
      // Create new customer
      console.log('Creating new customer with email:', data.customer.email);
      
      const { data: newCustomer, error: createError } = await supabase
        .from('customer')
        .insert([{
          title: data.customer.title,
          name: data.customer.name,
          email: data.customer.email,
          phone: data.customer.phone,
          address_1: data.customer.address_1,
          address_2: data.customer.address_2 || null,
          city: data.customer.city,
          postal_code: data.customer.postal_code,
          country: data.customer.country,
        }])
        .select('customer_id')
        .single();
        
      if (createError || !newCustomer) {
        console.error('Error creating customer:', createError);
        throw new Error(`Failed to create customer: ${createError?.message || 'Unknown error'}`);
      }
      
      customerId = newCustomer.customer_id;
      console.log('New customer created with ID:', customerId);
    }
    
    // 2. Create initial booking record
    console.log('Creating booking for customer ID:', customerId, 'Schedule ID:', data.scheduleId);
    
    // Create the booking with only the fields known to exist in the schema
    const bookingData = {
      course_sched_id: parseInt(data.scheduleId),
      customer_id: customerId,
      price_gbp: basePrice,
      payment_status: 'pending',
      status: 'pending',
    };

    const { data: booking, error: bookingError } = await supabase
      .from('booking')
      .insert([bookingData])
      .select('booking_id')
      .single();
      
    if (bookingError || !booking) {
      console.error('Error creating booking:', bookingError);
      throw new Error('Failed to create booking. Please try again later.');
    }
    
    console.log('Booking created with ID:', booking.booking_id);
    
    // 3. Create Stripe checkout session
    console.log('Creating Stripe checkout session...');
    
    try {
      const response = await supabase.functions.invoke('create-checkout-session', {
        body: {
          bookingId: booking.booking_id,
          customerId: customerId,
          customerEmail: data.customer.email,
          scheduleId: data.scheduleId,
          price: data.price,
          vatRate: VAT_RATE,
          vatAmount: vatAmount,
          totalPrice: totalPrice
        },
      });
      
      console.log('Checkout session response:', response);
      
      // Check if the response contains an error
      if (response.error) {
        console.error('Error from edge function:', response.error);
        throw new Error(`Payment processing error. Please try again later.`);
      }
      
      // Check if the response data contains an error field (this happens with custom error responses)
      if (response.data && response.data.error) {
        console.error('Error creating checkout session:', response.data.error);
        throw new Error(`Failed to process payment. Please try again later.`);
      }
      
      if (!response.data || !response.data.url) {
        console.error('Invalid checkout session response:', response);
        throw new Error('Unable to initiate payment. Please try again later.');
      }
      
      console.log('Stripe checkout session created:', response.data);
      
      // 4. Update booking with session ID
      if (response.data.sessionId) {
        const { error: updateError } = await supabase
          .from('booking')
          .update({
            stripe_session_id: response.data.sessionId,
          })
          .eq('booking_id', booking.booking_id);
          
        if (updateError) {
          console.error('Error updating booking with session ID:', updateError);
          // Not throwing here as the checkout session is already created
          toast({
            title: "Warning",
            description: "Your booking was created but we couldn't save all details. Please save your confirmation email.",
            variant: "destructive",
          });
        }
      }
      
      // Return the URL for redirection
      console.log('Booking created successfully, redirecting to checkout:', {
        bookingId: booking.booking_id,
        checkoutUrl: response.data.url
      });
      
      return {
        bookingId: booking.booking_id,
        checkoutUrl: response.data.url,
      };
    } catch (checkoutError) {
      console.error('Error during checkout session creation:', checkoutError);
      
      // Attempt to update the booking status to indicate failure
      try {
        await supabase
          .from('booking')
          .update({
            payment_status: 'failed',
            status: 'cancelled'
          })
          .eq('booking_id', booking.booking_id);
          
        console.log('Updated booking status to cancelled after checkout error');
      } catch (updateError) {
        console.error('Failed to update booking status after checkout error:', updateError);
      }
      
      throw new Error('We encountered an issue processing your payment. Please try again later.');
    }
  } catch (error) {
    console.error('Error in createBooking:', error);
    
    // Make sure we don't expose internal error details to users
    if (error instanceof Error) {
      // If it's already a sanitized error message, pass it through
      throw new Error(error.message);
    } else {
      throw new Error('An unexpected error occurred. Please try again later.');
    }
  }
}
