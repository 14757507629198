
import { Container } from "@/components/ui/Container";

export const StatsSection = () => {
  const stats = [
    { value: "5,000+", label: "Professionals Trained" },
    { value: "25+", label: "Years of Experience" },
    { value: "200+", label: "Corporate Clients" },
    { value: "97%", label: "Student Satisfaction" }
  ];

  return (
    <section className="py-16">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <div className="text-4xl font-bold text-primary mb-2">{stat.value}</div>
              <p className="text-muted-foreground">{stat.label}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};
