
import { Link } from "react-router-dom";
import { Navbar } from "@/components/layout/Navbar";
import { Footer } from "@/components/layout/Footer";
import { Container } from "@/components/ui/Container";
import { Button } from "@/components/ui/button";
import { SEOHead } from "@/components/seo/SEOHead";

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <SEOHead 
        title="Page Not Found | Fledgio"
        description="The page you're looking for could not be found. Navigate back to our homepage to explore our technical training courses."
        noIndex={true}
        canonical="https://www.fledgio.com/404"
      />
      <Navbar />
      <main className="flex-grow pt-36 md:pt-48">
        <Container className="py-12">
          <div className="max-w-lg mx-auto text-center">
            <h1 className="text-9xl font-bold text-primary mb-6">404</h1>
            <h2 className="heading-2 mb-4">Page Not Found</h2>
            <p className="text-muted-foreground mb-8">
              The page you're looking for doesn't exist or has been moved.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Button asChild size="lg">
                <Link to="/">Back to Home</Link>
              </Button>
              <Button asChild variant="outline" size="lg">
                <Link to="/courses">Browse Courses</Link>
              </Button>
            </div>
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default NotFound;
