
import { supabase } from '@/integrations/supabase/client';

export { supabase };

// Add helper functions that may be used across multiple files
export function formatDateString(date: Date): string {
  return date.toISOString().split('T')[0];
}

// Helper to map status to allowed values
export function mapStatus(status: string | null): "upcoming" | "ongoing" | "completed" | "cancelled" {
  if (status === "ongoing") return "ongoing";
  else if (status === "completed") return "completed"; 
  else if (status === "cancelled") return "cancelled";
  else return "upcoming"; // Default
}
