
import { ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Container } from "@/components/ui/Container";
import { Link } from "react-router-dom";
import { ContactFormModal } from "./ContactFormModal";

export function Hero() {
  return (
    <div className="relative pt-36 md:pt-40 overflow-hidden">
      <div className="absolute inset-0 z-0">
        <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-l from-primary/10 to-transparent rounded-full blur-3xl" />
        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 bg-gradient-to-r from-lite-green/10 to-transparent rounded-full blur-3xl" />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 h-1/3 bg-gradient-to-t from-lite-red/10 to-transparent rounded-full blur-3xl" />
      </div>
      
      <Container className="relative z-10 pt-4 pb-8 md:pt-8 md:pb-12">
        <div className="flex flex-col md:flex-row items-center gap-6 mb-10">
          <div className="max-w-xs">
            <div className="rounded-lg overflow-hidden">
              <img 
                src="/lovable-uploads/c14ec695-bd73-4664-b106-2ef743890782.png" 
                alt="Enabling your workforce to grow stronger wings" 
                className="w-full"
                width="600"
                height="350"
                loading="eager"
              />
            </div>
          </div>
          
          <div className="flex-1 rounded-lg p-5 flex items-center">
            <p className="text-foreground text-sm md:text-base leading-relaxed" style={{ fontSize: "115%" }}>
              Fledgio exists to enable your Data &amp; AI vision to progress from POC to Production. We believe this is best achieved by looking no further than your own existing workforce - and investing in their training and development, resulting in a more productive, happy and loyal team.
              <br/>We have been delivering world class training for 25 years now. You can use us to either deliver one-off courses, or work with you to develop your very own corporate Data &amp; AI, Software and Cloud training program.
              <br/>Our ethos:
              <ul className="list-disc pl-6 mt-2">
                <li>Security from the ground-up</li>
                <li>Performance and optimisation by design</li>
                <li>Sustainable and responsible innovation</li>
              </ul>
              <br/>If this resonates, then <ContactFormModal>lets talk</ContactFormModal>.
            </p>
          </div>
        </div>
        
        <div className="max-w-4xl mx-auto">
          <div className="bg-white rounded-lg shadow-sm p-8 mb-8 relative">
            {/* Ferris the Rustacean image */}
            <div className="absolute -top-12 -right-6 z-10">
              <img 
                src="/lovable-uploads/f520d6be-797a-45e1-b7bc-83a83441d9ba.png" 
                alt="Ferris the Rustacean" 
                className="w-36 md:w-48"
              />
            </div>
            
            <div className="text-center w-full">
              <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-6 animate-fade-in">
                <span>Professional Rust Training in London</span>
              </div>
              
              <h1 className="heading-1 mb-6 animate-slide-down w-full">
                Industry Leading Training and Advisory in Rust Development for Production
              </h1>
              
              <p className="text-xl text-muted-foreground mb-8 w-full mx-auto animate-slide-down animate-delay-100">
                <a 
                  href="https://www.linkedin.com/in/thedatachef/" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-primary font-medium underline hover:text-primary/80 transition-colors"
                >
                  thedatachef
                </a> brings 25 years experience developing and delivering world class training programs to thousands of professionals in the UK and beyond. Our Rust developer programs span introductory to specialist in IOT, MLOps, EdgeAI, Embedded and Blockchain technologies. Rust is renowned for safety and high performance and is especially becoming the go-to in hi-reg environments. Explore our course catalog or reach out if unsure and ask.
              </p>
              
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4 animate-slide-down animate-delay-200">
                <Button asChild size="lg" className="w-full sm:w-auto">
                  <Link to="/courses">
                    Explore Training Courses
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </Button>
                <Button asChild variant="outline" size="lg" className="w-full sm:w-auto">
                  <a href="#upcoming-courses">View Upcoming Courses</a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
