
import { useState, useEffect } from "react";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

type CookieSettings = {
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
};

export function CookieConsent() {
  const [open, setOpen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [cookieSettings, setCookieSettings] = useState<CookieSettings>({
    necessary: true, // Required cookies, always true
    preferences: false,
    statistics: false,
    marketing: false,
  });
  const { toast } = useToast();

  useEffect(() => {
    // Check if user has already set cookie preferences
    const hasConsent = localStorage.getItem("cookie-consent");
    
    if (!hasConsent) {
      // Show banner after a short delay for better UX
      const timer = setTimeout(() => setOpen(true), 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const acceptAll = () => {
    const allAccepted = {
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: true,
    };
    
    setCookieSettings(allAccepted);
    localStorage.setItem("cookie-consent", JSON.stringify(allAccepted));
    localStorage.setItem("cookie-consent-date", new Date().toISOString());
    setOpen(false);
    
    toast({
      title: "Cookies accepted",
      description: "Thank you! Your cookie preferences have been saved.",
    });
  };

  const acceptNecessary = () => {
    const necessaryOnly = {
      necessary: true,
      preferences: false,
      statistics: false,
      marketing: false,
    };
    
    setCookieSettings(necessaryOnly);
    localStorage.setItem("cookie-consent", JSON.stringify(necessaryOnly));
    localStorage.setItem("cookie-consent-date", new Date().toISOString());
    setOpen(false);
    
    toast({
      title: "Necessary cookies accepted",
      description: "Only necessary cookies will be used.",
    });
  };

  const saveSettings = () => {
    localStorage.setItem("cookie-consent", JSON.stringify(cookieSettings));
    localStorage.setItem("cookie-consent-date", new Date().toISOString());
    setShowSettings(false);
    setOpen(false);
    
    toast({
      title: "Cookie preferences saved",
      description: "Your custom cookie preferences have been saved.",
    });
  };

  const handleChange = (key: keyof CookieSettings) => {
    setCookieSettings(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  if (!open) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 p-4">
      <div className="max-w-3xl mx-auto">
        <div className="glass rounded-lg p-4 shadow-lg bg-white border border-primary/10">
          {!showSettings ? (
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
              <div className="pr-6">
                <h3 className="text-lg font-semibold text-foreground">This website uses cookies</h3>
                <p className="text-sm text-muted-foreground mt-1">
                  We use cookies to improve your experience and for marketing. You can customize your preferences.
                </p>
              </div>
              <div className="flex flex-wrap gap-2 md:ml-auto">
                <Button variant="outline" size="sm" onClick={() => setShowSettings(true)}>
                  Customize
                </Button>
                <Button variant="outline" size="sm" onClick={acceptNecessary}>
                  Necessary Only
                </Button>
                <Button variant="default" size="sm" onClick={acceptAll}>
                  Accept All
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold text-foreground">Cookie Settings</h3>
                <Button 
                  variant="ghost" 
                  size="icon" 
                  onClick={() => setShowSettings(false)}
                  className="h-8 w-8"
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
              <div className="space-y-4">
                <div className="flex items-start justify-between">
                  <div>
                    <h4 className="font-medium">Necessary Cookies</h4>
                    <p className="text-sm text-muted-foreground">Required for the website to function properly.</p>
                  </div>
                  <input 
                    type="checkbox" 
                    checked={cookieSettings.necessary} 
                    disabled 
                    className="rounded border-gray-300 text-primary"
                  />
                </div>
                
                <div className="flex items-start justify-between">
                  <div>
                    <h4 className="font-medium">Preference Cookies</h4>
                    <p className="text-sm text-muted-foreground">Allow the website to remember your preferences.</p>
                  </div>
                  <input 
                    type="checkbox" 
                    checked={cookieSettings.preferences} 
                    onChange={() => handleChange('preferences')}
                    className="rounded border-gray-300 text-primary"
                  />
                </div>
                
                <div className="flex items-start justify-between">
                  <div>
                    <h4 className="font-medium">Statistics Cookies</h4>
                    <p className="text-sm text-muted-foreground">Help us understand how you use the website.</p>
                  </div>
                  <input 
                    type="checkbox" 
                    checked={cookieSettings.statistics} 
                    onChange={() => handleChange('statistics')}
                    className="rounded border-gray-300 text-primary"
                  />
                </div>
                
                <div className="flex items-start justify-between">
                  <div>
                    <h4 className="font-medium">Marketing Cookies</h4>
                    <p className="text-sm text-muted-foreground">Used to deliver relevant advertisements.</p>
                  </div>
                  <input 
                    type="checkbox" 
                    checked={cookieSettings.marketing} 
                    onChange={() => handleChange('marketing')}
                    className="rounded border-gray-300 text-primary"
                  />
                </div>
              </div>
              <div className="mt-6 flex justify-end gap-2">
                <Button variant="outline" size="sm" onClick={() => setShowSettings(false)}>
                  Cancel
                </Button>
                <Button variant="default" size="sm" onClick={saveSettings}>
                  Save Preferences
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
