import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Navbar } from "@/components/layout/Navbar";
import { Footer } from "@/components/layout/Footer";
import { Container } from "@/components/ui/Container";
import { getAllCourses, getCourseCategories } from "@/lib/supabase";
import { toast } from "@/hooks/use-toast";
import { Course } from "@/types";
import { CourseFilters } from "@/components/courses/CourseFilters";
import { CourseList } from "@/components/courses/CourseList";
import { getCategoryEmoji } from "@/utils/courseUtils";

const dummyCourses = [
  {
    id: "1",
    title: "Rust Programming Fundamentals",
    slug: "rust-programming-fundamentals",
    description: "Learn the core concepts of Rust programming language including ownership, borrowing, and lifetimes.",
    category: "rust",
    level: "beginner",
    price: 499,
    duration: "3 days",
    imageUrl: "https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    topics: ["Ownership Model", "Memory Safety", "Error Handling", "Concurrency"],
    prerequisites: ["Basic programming experience"],
    createdAt: "2023-01-15T00:00:00.000Z",
    updatedAt: "2023-01-15T00:00:00.000Z",
  },
  {
    id: "2",
    title: "Advanced Rust Development",
    slug: "advanced-rust-development",
    description: "Take your Rust skills to the next level with advanced patterns, performance optimization, and FFI.",
    category: "rust",
    level: "advanced",
    price: 699,
    duration: "4 days",
    imageUrl: "https://images.unsplash.com/photo-1623479322729-28b25c16b011?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    topics: ["Advanced Traits", "Unsafe Rust", "FFI", "Performance Optimization"],
    prerequisites: ["Rust Programming Fundamentals or equivalent experience"],
    createdAt: "2023-02-10T00:00:00.000Z",
    updatedAt: "2023-02-10T00:00:00.000Z",
  },
  {
    id: "3",
    title: "Python for Data Analysis",
    slug: "python-for-data-analysis",
    description: "Master Python libraries like Pandas, NumPy, and Matplotlib for effective data analysis and visualization.",
    category: "python",
    level: "intermediate",
    price: 549,
    duration: "3 days",
    imageUrl: "https://images.unsplash.com/photo-1515879218367-8466d910aaa4?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    topics: ["Data Manipulation with Pandas", "Statistical Analysis", "Data Visualization", "Cleaning Data"],
    prerequisites: ["Basic Python knowledge"],
    createdAt: "2023-03-05T00:00:00.000Z",
    updatedAt: "2023-03-05T00:00:00.000Z",
  },
  {
    id: "4",
    title: "Machine Learning with Python",
    slug: "machine-learning-with-python",
    description: "Learn to implement various machine learning algorithms using Python's scikit-learn and TensorFlow.",
    category: "python",
    level: "advanced",
    price: 799,
    duration: "5 days",
    imageUrl: "https://images.unsplash.com/photo-1550751827-4bd374c3f58b?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    topics: ["Supervised Learning", "Unsupervised Learning", "Neural Networks", "Model Evaluation"],
    prerequisites: ["Python for Data Analysis or equivalent experience"],
    createdAt: "2023-04-20T00:00:00.000Z",
    updatedAt: "2023-04-20T00:00:00.000Z",
  },
  {
    id: "5",
    title: "Docker and Kubernetes Fundamentals",
    slug: "docker-kubernetes-fundamentals",
    description: "Get started with containerization and orchestration using Docker and Kubernetes.",
    category: "cloud",
    level: "beginner",
    price: 599,
    duration: "4 days",
    imageUrl: "https://images.unsplash.com/photo-1667372393119-3d4c48d07fc9?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    topics: ["Container Basics", "Docker Compose", "Kubernetes Architecture", "Pod Deployment"],
    prerequisites: ["Basic command line experience"],
    createdAt: "2023-05-12T00:00:00.000Z",
    updatedAt: "2023-05-12T00:00:00.000Z",
  },
  {
    id: "6",
    title: "Multi-Cloud Architecture",
    slug: "multi-cloud-architecture",
    description: "Design and implement solutions that work seamlessly across different cloud providers.",
    category: "cloud",
    level: "advanced",
    price: 899,
    duration: "5 days",
    imageUrl: "https://images.unsplash.com/photo-1639322537228-f710d846310a?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    topics: ["Cloud Architecture Patterns", "Hybrid Cloud", "Multi-Cloud Networking", "Cost Optimization"],
    prerequisites: ["Docker and Kubernetes Fundamentals or equivalent experience"],
    createdAt: "2023-06-18T00:00:00.000Z",
    updatedAt: "2023-06-18T00:00:00.000Z",
  },
];

const Courses = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState(true);
  const [filteredCourses, setFilteredCourses] = useState<Course[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeCategory, setActiveCategory] = useState<string | null>(
    searchParams.get("category")
  );
  const [activeLevel, setActiveLevel] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  useEffect(() => {
    async function fetchCategories() {
      try {
        setLoadingCategories(true);
        const data = await getCourseCategories();
        
        if (data && data.length > 0) {
          setCategories(data);
        } else {
          console.log("No categories found, using defaults");
          setCategories(["rust", "python", "cloud"]);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        setCategories(["rust", "python", "cloud"]);
      } finally {
        setLoadingCategories(false);
      }
    }

    fetchCategories();
  }, []);

  useEffect(() => {
    async function fetchCourses() {
      try {
        setLoading(true);
        const data = await getAllCourses();
        
        if (data && data.length > 0) {
          setCourses(data);
        } else {
          console.log("No courses found or error fetching from Supabase, using dummy data");
          setCourses(dummyCourses);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
        toast({
          title: "Error fetching courses",
          description: "Failed to load courses. Using sample data instead.",
          variant: "destructive",
        });
        setCourses(dummyCourses);
      } finally {
        setLoading(false);
      }
    }

    fetchCourses();
  }, []);
  
  useEffect(() => {
    let results = courses;
    
    if (activeCategory) {
      results = results.filter((course) => course.category.toLowerCase() === activeCategory.toLowerCase());
    }
    
    if (activeLevel) {
      results = results.filter((course) => course.level.toLowerCase() === activeLevel.toLowerCase());
    }
    
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      results = results.filter(
        (course) =>
          course.title.toLowerCase().includes(lowerSearchTerm) ||
          course.description.toLowerCase().includes(lowerSearchTerm)
      );
    }
    
    setFilteredCourses(results);
  }, [courses, activeCategory, activeLevel, searchTerm]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (activeCategory) params.set("category", activeCategory);
    if (activeLevel) params.set("level", activeLevel);
    setSearchParams(params);
  }, [activeCategory, activeLevel, setSearchParams]);

  const handleCategoryFilter = (category: string) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  const handleLevelFilter = (level: string) => {
    setActiveLevel(activeLevel === level ? null : level);
  };

  const clearFilters = () => {
    setActiveCategory(null);
    setActiveLevel(null);
    setSearchTerm("");
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow pt-36 md:pt-48">
        <div className="bg-gray-50 border-b border-gray-200 py-12">
          <Container>
            <div className="max-w-3xl">
              <h1 className="heading-1 mb-4">Training Courses</h1>
              <p className="text-lg text-muted-foreground">
                Browse our specialized technical training programs designed by industry experts
              </p>
            </div>
          </Container>
        </div>
        
        <Container className="py-8">
          <CourseFilters 
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            activeLevel={activeLevel}
            setActiveLevel={setActiveLevel}
            categories={categories}
            loadingCategories={loadingCategories}
            getCategoryEmoji={getCategoryEmoji}
            handleCategoryFilter={handleCategoryFilter}
            handleLevelFilter={handleLevelFilter}
            clearFilters={clearFilters}
          />
          
          <CourseList 
            loading={loading}
            filteredCourses={filteredCourses}
            getCategoryEmoji={getCategoryEmoji}
            clearFilters={clearFilters}
          />
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default Courses;
