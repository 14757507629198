
export const categoryEmojis: Record<string, string> = {
  rust: "🦀",
  python: "🐍",
  cloud: "🐳",
  javascript: "🟨",
  typescript: "🔷",
  go: "🐹",
};

export const getCategoryEmoji = (category: string): string => {
  const categoryLower = category ? category.toLowerCase() : '';
  return categoryEmojis[categoryLower] || "📚";
};
