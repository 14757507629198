
// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://xijxjnwtaglnjrkcoemr.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inhpanhqbnd0YWdsbmpya2NvZW1yIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDI0NjM4MzYsImV4cCI6MjA1ODAzOTgzNn0.tLLzbJ-DUTp0BmKiSUXZMRvyBESvpmB8oQpbbqFM5lE";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);
